import React, { useState, useContext } from "react";

import { useNavigate, Link } from "react-router-dom";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import {
	Button,
	Row,
	Form,
	Card,
	Col,
	Container,
	Spinner,
	Alert,
} from "react-bootstrap";

import { auth } from "../../config/firebase";
import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
} from "firebase/auth";

import * as Yup from "yup";

import { createUserDb } from "../../services/authService";

import Particles from "../../components/Particles";

const loginSchema = Yup.object().shape({
	email: Yup.string()
		.email("Invalid email format")
		.required("Email is required"),
	password: Yup.string()
		.min(6, "Password must be at least 6 characters")
		.required("Password is required"),
});

const signUpSchema = Yup.object().shape({
	email: Yup.string()
		.email("Invalid email format")
		.required("Email is required"),
	password: Yup.string()
		.min(6, "Password must be at least 6 characters")
		.required("Password is required"),
	firstName: Yup.string().required("First name is required"),
	lastName: Yup.string().required("Last name is required"),
});

const Auth = () => {
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();
	const [isLoginMode, setIsLoginMode] = useState(true);
	const [authError, setAuthError] = useState("");
	const { isLoading, sendRequest } = useHttpClient();
	const [formValues, setFormValues] = useState({
		email: "",
		password: "",
		firstName: "",
		lastName: "",
	});

	const validationSchema = isLoginMode ? loginSchema : signUpSchema;

	const switchModeHandler = () => {
		setIsLoginMode((prevMode) => !prevMode);
		if (isLoginMode) {
			setFormValues((prevValues) => ({
				email: prevValues.email,
				password: prevValues.password,
				firstName: "",
				lastName: "",
			}));
		}
		setAuthError("");
	};

	const authSubmitHandler = async (values) => {
		const { email, password, firstName, lastName } = values;
		try {
			if (isLoginMode) {
				await signInWithEmailAndPassword(auth, email, password); //signs in with firebase
			} else {
				await createUserWithEmailAndPassword(auth, email, password); //creates user with firebase
				await createUserDb(
					auth.currentUser.accessToken,
					firstName,
					lastName,
					sendRequest
				);
				//createUser in DB
			}
			authContext.login(auth.currentUser.uid, auth.currentUser.accessToken);
			navigate("/");
		} catch (error) {
			setAuthError(
				"Wrong email or password. Try again or click Forgot password to reset it."
			);
		}
	};

	return (
		<React.Fragment>
			<Particles
				className="absolute inset-0 z-0"
				quantity={100}
				ease={100}
				color="#1c004b"
				enable={false}
			/>
			{isLoading && <Spinner animation="border" />}
			<Container>
				<Row className="align-items-center" style={{ height: "100vh" }}>
					<Col xs={12} md={6}>
						<img
							src="/logo.png"
							alt="Ratava Logo"
							style={{ maxWidth: "100%", maxHeight: "400px" }}
						></img>
					</Col>
					<Col xs={12} md={6}>
						<Card
							style={{
								width: "18rem",
								margin: "0 auto",
								backgroundColor: "rgba(255, 255, 255, 0.5)",
							}}
						>
							<Card.Body>
								<Card.Title>{!isLoginMode ? "Sign Up" : "Log In"}</Card.Title>
								<Formik
									initialValues={formValues}
									validationSchema={validationSchema}
									onSubmit={authSubmitHandler}
									enableReinitialize={true}
								>
									{({
										handleSubmit,
										values,
										touched,
										errors,
										isValid,
										dirty,
									}) => (
										<FormikForm noValidate onSubmit={handleSubmit}>
											{!isLoginMode && (
												<div>
													<Row className="mb-3">
														<Form.Group md="6" controlId="firstName">
															<Form.Label>First Name</Form.Label>
															<Field
																as={Form.Control}
																value={values.firstName}
																type="text"
																name="firstName"
																isInvalid={
																	touched.firstName && !!errors.firstName
																}
																isValid={touched.firstName && !errors.firstName}
															/>
															<ErrorMessage
																name="firstName"
																component="div"
																className="text-danger"
															/>
														</Form.Group>
													</Row>
													<Row className="mb-3">
														<Form.Group md="6" controlId="lastName">
															<Form.Label>Last Name</Form.Label>
															<Field
																as={Form.Control}
																value={values.lastName}
																type="text"
																name="lastName"
																isInvalid={
																	touched.lastName && !!errors.lastName
																}
																isValid={touched.lastName && !errors.lastName}
															/>
															<ErrorMessage
																name="lastName"
																component="div"
																className="text-danger"
															/>
														</Form.Group>
													</Row>
												</div>
											)}
											<Row className="mb-3">
												<Form.Group md="6" controlId="email">
													<Form.Label>E-Mail</Form.Label>
													<Field
														as={Form.Control}
														value={values.email}
														type="email"
														name="email"
														isInvalid={touched.email && !!errors.email}
														isValid={touched.email && !errors.email}
													/>
													<ErrorMessage
														name="email"
														component="div"
														className="text-danger"
													/>
												</Form.Group>
											</Row>
											<Row className="mb-3">
												<Form.Group md="6" controlId="password">
													<Form.Label>Password</Form.Label>
													<Field
														as={Form.Control}
														value={values.password}
														type="password"
														name="password"
														isInvalid={touched.password && !!errors.password}
														isValid={touched.password && !errors.password}
													/>
													<ErrorMessage
														name="password"
														component="div"
														className="text-danger"
													/>
												</Form.Group>
											</Row>
											{authError && (
												<Alert
													variant="danger"
													onClose={() => setAuthError("")}
													dismissible
												>
													{authError}
												</Alert>
											)}
											<Button
												className="submit-button"
												variant="primary"
												type="submit"
												disabled={!(isValid && dirty)}
											>
												{isLoginMode ? "Log In" : "Sign Up"}
											</Button>
											<div class="g-recaptcha-badge">
												This site is protected by reCAPTCHA and the Google&nbsp;
												<a
													href="https://policies.google.com/privacy"
													target="_blank"
													rel="noopener noreferrer"
												>
													Privacy Policy
												</a>
												&nbsp;and&nbsp;
												<a
													href="https://policies.google.com/terms"
													target="_blank"
													rel="noopener noreferrer"
												>
													Terms of Service
												</a>
												&nbsp;apply.
											</div>
										</FormikForm>
									)}
								</Formik>
								<p>
									<Link to="/forgot-password">Forgot Password?</Link>
								</p>
								<Button
									style={{ marginTop: "1rem" }}
									onClick={switchModeHandler}
								>
									{isLoginMode ? "Switch to Sign Up" : "Switch to Log In"}
								</Button>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	);
};

export default Auth;
