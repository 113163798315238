import React, { useState, useEffect, useContext, useCallback } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { ListGroup, Spinner } from "react-bootstrap";
import ToastError from "../../shared/elements/ToastError";

const UserList = () => {
    const authContext = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [users, setUsers] = useState([]);

    const fetchUsers = useCallback(async () => {
        try {
            const response = await sendRequest(
                "/api/users/organization",
                "GET",
                null,
                {
                    Authorization: "Bearer " + authContext.token,
                }
            );
            const responseData = response.data;
            if (Array.isArray(responseData)) {
                setUsers(responseData);
            } else {
                setUsers([]);
            }
        } catch (err) {
            console.error('Error fetching users:', err);
        }
    }, [sendRequest, authContext.token]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);


    return (
        <div>
            <ToastError show={!!error} message={error} onClose={clearError} />
            {isLoading && <Spinner animation="border" />}
            <ListGroup>
                {users.length === 0 && <ListGroup.Item>No users found.</ListGroup.Item>}
                {users.map((user) => (
                    <ListGroup.Item 
                        key={user.id}
                    >
                        {user.firstName} {user.lastName} - {user.email} - {user.role}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    );
};

export default UserList;
