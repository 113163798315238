import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "./auth-context";

const SuperAdminRoute = () => {
    const authContext = useContext(AuthContext);

    if (!authContext.isAuthReady) {
        return <p>Loading...</p>;
    }

    if (!authContext.isLoggedIn) {
        return <Navigate to="/login" />;
    }

    return authContext.isSuperAdmin ? <Outlet /> : <Navigate to="/unauthorized" />;
};

export default SuperAdminRoute;
