import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import ToastError from "../../shared/elements/ToastError";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import {
	Button,
	Row,
	Col,
	Form,
	Card,
	Toast,
	Spinner,
	Offcanvas,
} from "react-bootstrap";
import * as Yup from "yup";
//import EntityDropdown from "./EntityDropdown";
//import NewCampaignModal from "./NewCampaignModal";

const scriptSchema = Yup.object().shape({
	title: Yup.string().required("Script title is required"),
	content: Yup.string().required("Content is required"),
	orgId: Yup.string().required("Organization is required"),
});

const ScriptEditor = ({ onScriptUpdated = () => {} }) => {
	const authContext = useContext(AuthContext);
	const { isLoading, error, sendRequest, clearError } = useHttpClient();
	const { scriptId } = useParams();
	const navigate = useNavigate();
	const [initialValues, setInitialValues] = useState(null);
	const [userOrganizations, setUserOrganizations] = useState([]);
	const [selectedOrgId, setSelectedOrgId] = useState("");
	const [showOrganizationDropdown, setShowOrganizationDropdown] =
		useState(false);
	const [showToast, setShowToast] = useState(false);

	const [scriptData, setScriptData] = useState(null);
	const [organizationsLoaded, setOrganizationsLoaded] = useState(false);

	const [showOffcanvas, setShowOffcanvas] = useState(false);
	const handleClose = () => setShowOffcanvas(false);
	const handleShow = () => setShowOffcanvas(true);

	useEffect(() => {
		const fetchScript = async () => {
			if (scriptId) {
				try {
					const response = await sendRequest(
						`/api/scripts/${scriptId}`,
						"GET",
						null,
						{
							Authorization: "Bearer " + authContext.token,
						}
					);
					console.log("Script data:", response.data);
					const script = response.data;
					setScriptData({
						title: script.title,
						content: script.content,
						orgId: script.orgId || "",
						userId: script.userId || "",
					});
				} catch (err) {
					console.error(err);
				}
			} else {
				setScriptData({
					title: "",
					content: "",
					orgId: "",
				});
			}
		};

		const fetchUserOrganizations = async () => {
			try {
				let organizations = [];

				if (authContext.isSuperAdmin) {
					// Super admin can fetch all organizations
					const response = await sendRequest(
						"/api/organizations",
						"GET",
						null,
						{
							Authorization: "Bearer " + authContext.token,
						}
					);
					organizations = response.data;
				} else {
					// Regular users and org admins get their memberships
					organizations = authContext.memberships;
				}

				setUserOrganizations(organizations);

				const showDropdown =
					authContext.isSuperAdmin || organizations.length > 1;
				setShowOrganizationDropdown(showDropdown);
				// If currentOrganization is null
				setOrganizationsLoaded(true);
			} catch (err) {
				console.error("Error fetching organizations:", err);
			}
		};

		fetchScript();
		fetchUserOrganizations();
	}, [
		sendRequest,
		authContext.token,
		scriptId,
		authContext.isSuperAdmin,
		authContext.memberships,
	]);

	useEffect(() => {
		if (scriptData && organizationsLoaded) {
			let orgId = scriptData.orgId;

			if (showOrganizationDropdown) {
				// If editing a script, use its orgId; else, default to first org
				orgId = orgId || userOrganizations[0]?.orgId || "";
			} else if (userOrganizations.length === 1) {
				orgId = userOrganizations[0].orgId;
			} else {
				// No organizations found
				console.error("No organizations available for the user.");
			}

			setSelectedOrgId(orgId);

			setInitialValues({
				...scriptData,
				orgId,
			});
		}
	}, [
		scriptData,
		organizationsLoaded,
		showOrganizationDropdown,
		userOrganizations,
	]);

	const submitHandler = async (values, { setSubmitting, resetForm }) => {
		try {
			const payload = {
				...values,
			};

			// Ensure orgId is included
			if (!payload.orgId) {
				console.error("Organization ID is required");
				return;
			}

			if (scriptId) {
				await sendRequest(
					`/api/scripts/${scriptId}`,
					"PUT",
					JSON.stringify(payload),
					{
						"Content-Type": "application/json",
						Authorization: "Bearer " + authContext.token,
					}
				);
			} else {
				await sendRequest("/api/scripts", "POST", JSON.stringify(payload), {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authContext.token,
				});
			}
			resetForm();
			setShowToast(true);
			setTimeout(() => setShowToast(false), 3000);
			navigate("/scripts");
		} catch (error) {
			console.error(error);
		} finally {
			setSubmitting(false);
		}
	};

	const handleDeleteScript = async () => {
        if (!scriptData) return;
        try {
            await sendRequest(
                `/api/scripts/${scriptId}`,
                "DELETE",
                null,
                {
                    Authorization: "Bearer " + authContext.token,
                }
            );
			onScriptUpdated();
			navigate("/scripts");
        } catch (err) {
            console.error('Error deleting script:', err); // Debug log
        }
    };

	return (
		
		<React.Fragment>
			<ToastError show={!!error} message={error} onClose={clearError} />
			<div style={{ display: "flex", justifyContent: "center" }}>
				{isLoading && <Spinner animation="border" />}
				<Card style={{ width: "60rem" }}>
					<Card.Body>
						<Card.Title>
							<div
								style={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								{scriptId ? "Edit Script" : "Create Script"}
								<Button onClick={handleShow}>Tips</Button>
							</div>
						</Card.Title>
						{initialValues ? (
							<Formik
								initialValues={initialValues}
								validationSchema={scriptSchema}
								onSubmit={submitHandler}
								//enableReinitialize={true}
							>
								{(formikProps) => (
									<FormikForm noValidate onSubmit={formikProps.handleSubmit}>
										<Row>
											<Col md={6}>
												<Row className="mb-3">
													<Form.Group md="12" controlId="title">
														<Form.Label>Script Title</Form.Label>
														<Field as={Form.Control} type="text" name="title" />
														<ErrorMessage
															name="title"
															component="div"
															className="text-danger"
														/>
													</Form.Group>
												</Row>

												{/* Organization Selection */}
												{showOrganizationDropdown && (
													<Row className="mb-3">
														<Form.Group controlId="orgId">
															<Form.Label>Organization</Form.Label>
															<Form.Control
																as="select"
																name="orgId"
																value={
																	formikProps.values.orgId || selectedOrgId
																}
																onChange={(e) => {
																	setSelectedOrgId(e.target.value);
																	formikProps.setFieldValue(
																		"orgId",
																		e.target.value
																	);
																}}
															>
																<option value="">Select an organization</option>
																{userOrganizations.map((org) => (
																	<option key={org.orgId} value={org.orgId}>
																		{org.orgName || org.name}
																	</option>
																))}
															</Form.Control>
															<ErrorMessage
																name="orgId"
																component="div"
																className="text-danger"
															/>
														</Form.Group>
													</Row>
												)}
											</Col>
											<Col md={6}>
												<Row className="mb-3">
													<Form.Group md="12" controlId="content">
														<Field
															name="content"
															as="textarea"
															rows={20}
															placeholder="Hello! I'm the avatar of..."
															style={{ width: "100%", height: "400px", border: "1px solid #ced4da" }}
														/>
														<ErrorMessage
															name="content"
															component="div"
															className="text-danger"
														/>
													</Form.Group>
												</Row>
											</Col>
										</Row>
										<Button
											className="submit-button"
											variant="primary"
											type="submit"
											disabled={formikProps.isSubmitting || !formikProps.dirty}
										>
											{scriptId ? "Update" : "Create"}
										</Button>
										{scriptData &&( 
											//only show delete if user is the owner, or if user is orgadmin of script or superadmin
											(scriptData.userId === authContext.userId) ||
											(authContext.isSuperAdmin) ||
											(authContext.orgRole === "orgadmin" && scriptData.orgId === authContext.orgId)
										)
											&&
											(
											<Button
												className="delete-button"
												variant="danger"
												onClick={handleDeleteScript}
											>
												Delete
											</Button>
										)}
									</FormikForm>
								)}
							</Formik>
						) : (
							<Spinner animation="border" />
						)}
					</Card.Body>
				</Card>
				<div style={{ position: "fixed", top: 20, right: 20, zIndex: 1050 }}>
					<Toast
						onClose={() => setShowToast(false)}
						show={showToast}
						delay={3000}
						autohide
					>
						<Toast.Header>
							<strong className="me-auto">Success</strong>
							<small>Just now</small>
						</Toast.Header>
						<Toast.Body>Operation successful!</Toast.Body>
					</Toast>
				</div>
			</div>
			<Offcanvas show={showOffcanvas} onHide={handleClose} placement={"end"}>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>Script Editor Tips</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<h5>
						<span class="icon">✏️</span> Incorporate Punctuation Marks{" "}
						<span class="icon">✏️</span>
					</h5>
					<div class="section">
						<p>
							Use the following punctuation marks for better pronunciation and
							pauses:
						</p>
						<ul>
							<li>
								<strong>Hyphens (-):</strong> Separate syllables for clear
								pronunciation.
							</li>
							<li>
								<strong>Commas (,):</strong> Create shorter breaks.
							</li>
							<li>
								<strong>Periods (.):</strong> Introduce longer breaks with
								downward inflection.
							</li>
						</ul>
					</div>

					<h5>
						<span class="icon">🔤</span> Ensure Spelling and Language
						Consistency <span class="icon">🔤</span>
					</h5>
					<div class="section">
						<ul>
							<li>
								<strong>Spell Correctly:</strong> Double-check your script for
								spelling errors.
							</li>
							<li>
								<strong>Language Consistency:</strong> Avoid mixing languages.
								For instance, do not include Arabic words in an English script.
							</li>
						</ul>
					</div>

					<h5>
						<span class="icon">🗣️</span> Use our Pronunciation feature{" "}
						<span class="icon">🗣️</span>
					</h5>
					<div class="section">
						<p>
							If your script has some words or phrases that aren't standard
							language, you may want to sound them out directly.
              You can also use hyphens (-) to emphasize
							pronunciation.
						</p>
						<p>This is particularly useful with Acronyms:</p>
						<ul>
							<li>"AI" should be "a-eye."</li>
							<li>"AWS" becomes "a-double you-s."</li>
						</ul>
					</div>

					<h5>
						<span class="icon">🔢</span> How to write Numbers{" "}
						<span class="icon">🔢</span>
					</h5>
					<div class="section">
						<p>
							You can write out numbers or use phonetic spelling for clarity.
							Examples:
						</p>
						<ul>
							<li>"2012" should be "twenty twelve."</li>
							<li>"3/8" becomes "three eighths of an inch."</li>
							<li>"01:18" should be "one minute and eighteen seconds."</li>
							<li>
								"10-19-2016" is "October nineteenth two thousand sixteen."
							</li>
							<li>
								"150th CT NE, Redmond, WA" should be "150th Court Northeast
								Redmond Washington."
							</li>
						</ul>
					</div>
				</Offcanvas.Body>
			</Offcanvas>
		</React.Fragment>
		
	);
};

export default ScriptEditor;
