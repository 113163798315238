import React, { useContext } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Spinner } from "react-bootstrap";
import ToastError from "../../shared/elements/ToastError";
import { useNavigate } from "react-router-dom";

// Validation schema
const NewOrgSchema = Yup.object().shape({
    name: Yup.string().required("Organization name is required")
});

const NewOrg = () => {
    const authContext = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const navigate = useNavigate();

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            await sendRequest(
                "/api/organizations",
                "POST",
                JSON.stringify(values),
                {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + authContext.token,
                }
            );
            navigate("/organizations");
        } catch (err) {
            console.error('Error creating organization:', err); // Debug log
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div>
            <ToastError show={!!error} message={error} onClose={clearError} />
            <Formik
                initialValues={{ name: ""}}
                validationSchema={NewOrgSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <div className="mb-3">
                            <label htmlFor="name">Organization Name</label>
                            <Field
                                type="text"
                                name="name"
                                className="form-control"
                            />
                            <ErrorMessage
                                name="name"
                                component="div"
                                className="text-danger"
                            />
                        </div>
                        <Button
                            type="submit"
                            variant="primary"
                            disabled={isSubmitting || isLoading}
                        >
                            {isLoading ? <Spinner animation="border" /> : "Create Organization"}
                        </Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default NewOrg;
