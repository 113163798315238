import React, { useState, useEffect, useContext } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { Form, Button, Spinner } from "react-bootstrap";
import ToastError from "../../shared/elements/ToastError";

const MakeOrgAdmin = () => {
    const authContext = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [users, setUsers] = useState([]);
    const [organizations, setOrganizations] = useState([]); // State to store organizations
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedOrg, setSelectedOrg] = useState(''); // State to store selected organization

    useEffect(() => {
        const fetchUsersAndOrgs = async () => {
            try {
                // Fetch users
                const userResponse = await sendRequest(
                    "/api/users",
                    "GET",
                    null,
                    {
                        Authorization: "Bearer " + authContext.token,
                    }
                );
                setUsers(userResponse.data);

                // Fetch organizations
                const orgResponse = await sendRequest(
                    "/api/organizations",
                    "GET",
                    null,
                    {
                        Authorization: "Bearer " + authContext.token,
                    }
                );
                setOrganizations(orgResponse.data);
            } catch (err) {
                console.error('Error fetching data:', err);
            }
        };

        fetchUsersAndOrgs();
    }, [sendRequest, authContext.token]);

    const handleAddOrg = async () => {
        try {
            const response = await sendRequest(
                `/api/organizations/add-admin`,
                "POST",
                JSON.stringify({ userId: selectedUser, orgId: selectedOrg }),
                {
                    Authorization: "Bearer " + authContext.token,
                    "Content-Type": "application/json",
                }
            );
            alert(response.data.message);
        } catch (err) {
            console.error('Error adding user to org:', err);
        }
    };

    return (
        <div>
            <ToastError show={!!error} message={error} onClose={clearError} />
            {isLoading && <Spinner animation="border" />}
            <Form>
                <Form.Group controlId="orgSelect">
                    <Form.Label>Select an Organization</Form.Label>
                    <Form.Control as="select" value={selectedOrg} onChange={(e) => setSelectedOrg(e.target.value)}>
                        <option value="">Select an organization</option>
                        {organizations.map((org) => (
                            <option key={org.orgId} value={org.orgId}>
                                {org.name}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="userSelect">
                    <Form.Label>Select a User</Form.Label>
                    <Form.Control as="select" value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                        <option value="">Select a user</option>
                        {users.map((user) => (
                            <option key={user.id} value={user.id}>
                                {user.firstName} {user.lastName} ({user.email})
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Button variant="primary" onClick={handleAddOrg} disabled={!selectedUser || !selectedOrg}>
                    Make User to Organization Admin
                </Button>
            </Form>
        </div>
    );
};

export default MakeOrgAdmin;
