import React, { useContext, useEffect, useState } from "react";
import { useFetchEntities } from "../../shared/hooks/useFetchEntities";
import { AuthContext } from "../../shared/context/auth-context";
import { Form, Spinner } from "react-bootstrap";

const EntityDropdown = ({ entity, value, onChange, filter }) => {
    const authContext = useContext(AuthContext);
    const { entities, loading, error } = useFetchEntities(entity, authContext.token, filter);
    const [selectedValue, setSelectedValue] = useState(value);

    useEffect(() => {
        setSelectedValue(value);
    }, [value, entities]);

    // Remove 's' at the end if it exists and capitalize the first letter
    let label = entity.endsWith("s") 
        ? entity.charAt(0).toUpperCase() + entity.slice(1, -1) 
        : entity.charAt(0).toUpperCase() + entity.slice(1);

    let title = label;
    if (entity === "campaigns" || entity === "clients") {
        title += " (optional)";
    }

    return (
        <Form.Group controlId={entity}>
            <Form.Label>{title}</Form.Label>
            {loading ? (
                <Spinner animation="border" />
            ) : (
                <Form.Control as="select" value={selectedValue} onChange={(e) => {
                    setSelectedValue(e.target.value);
                    onChange(e);
                }}>
                    <option value="">Select {label}</option>
                    {entities.map((item) => (
                        <option key={item.id} value={item.id}>
                            {item.title || item.name || item.clientName || item.avatar_name}
                        </option>
                    ))}
                </Form.Control>
            )}
            {error && <div className="text-danger">{error}</div>}
        </Form.Group>
    );
};

export default EntityDropdown;
