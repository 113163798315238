import React, { useEffect, useState, useContext } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { Card, Row, Col, Modal } from "react-bootstrap";
import { AuthContext } from "../../shared/context/auth-context";

const AvatarSelection = ({ value, onChange }) => {
    const authContext = useContext(AuthContext);
    const { sendRequest } = useHttpClient();
    const [avatars, setAvatars] = useState([]);
    const [selectedAvatar, setSelectedAvatar] = useState(value?.avatarId || null);
    const [selectedLook, setSelectedLook] = useState(value?.lookId || null);
    const [showModal, setShowModal] = useState(false);
    const [looks, setLooks] = useState([]);

    const handleCardClick = (avatar) => {
        if (avatar.scenes.length < 2) {
            setSelectedAvatar(avatar);    
            onChange({ avatarId: avatar.id, lookId: avatar.scenes[0]?.id || null });
            return;
        }
        setSelectedAvatar(avatar);
        setShowModal(true);
        setLooks(avatar.scenes);
    };

    const handleLookClick = (look) => {
        setSelectedLook(look);
        onChange({ avatarId: selectedAvatar.id, lookId: look.id });
        setShowModal(false);
    };


    useEffect(() => {
        const fetchAvatars = async () => {
            try {
                const response = await sendRequest("/api/avatars", "GET", null, {
                    Authorization: "Bearer " + authContext.token,
                });
                setAvatars(response.data);
            } catch (err) {
                console.error("Error fetching avatars:", err);
            }
        };

        fetchAvatars();
    }, [sendRequest, authContext.token]);


    return (
        <React.Fragment>
        <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
            <p>Avatar</p>
            <Row>
                {avatars.map((avatar) => (
                    <Col key={avatar.id} xs={3} sm={3} md={2} style={{ display: "inline-block", float: "none" }}>
                        <Card
                            className={selectedAvatar === avatar ? "border-primary" : ""}
                            onClick={() => handleCardClick(avatar)}
                            style={{ cursor: "pointer", margin: "5px", width: "100%" }}
                        >
                            <Card.Img variant="top" src={avatar.image_url} />
                            <Card.Body>
                                <Card.Title style={{ fontSize: "0.8rem", textAlign: "center" }}>
                                    {avatar.avatar_name}
                                </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
        <Modal show={showModal} onHide={() => setShowModal(false)} size="md">
            <Modal.Header closeButton>
                <Modal.Title>{`Select a scene for ${selectedAvatar?.avatar_name}` || "Selected Avatar"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {looks.length > 0 ? (
                    <div style = {{
                        maxWidth: "100%",
                        maxHeight: "400px",
                        overflowY: "auto",
                        overflowX: "hidden",
                        margin: "0 auto"
                    }}>
                        <Row xs={1} md={2} lg={2} className="g-4">
                            {looks.map((look) => (
                                <Col key={look.id}>
                                    <Card 
                                        className={`card-clickable ${selectedLook === look ? "border-primary" : ""}`}
                                        onClick={() => handleLookClick(look)}
                                        style={{ width: "13rem" }}>
                                            <Card.Img src={look.image_url} />
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                ): (
                    <p>No looks available for this avatar</p>
                )}
            </Modal.Body>
        </Modal>
        </React.Fragment>
    );
};

export default AvatarSelection;
