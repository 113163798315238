import React, { useEffect, useState, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

const VideoPlayer = () => {  
  const [searchParams] = useSearchParams();
  const videoId = searchParams.get("id");
  const { sendRequest } = useHttpClient();
  const authContext = useContext(AuthContext);
  const [videoDetails, setVideoDetails] = useState(null);

  useEffect(() => {
    const fetchVideoDetails = async () => {
      if (videoId) {
        try {
          const response = await sendRequest(
            `/api/videos/${videoId}/player`,
            "GET",
            null,
            {
              Authorization: "Bearer " + authContext.token,
            }
          );
          setVideoDetails(response.data);
        } catch (err) {
          console.error("Error fetching video details:", err);
        }
      }
    };

    fetchVideoDetails();
  }, [videoId, sendRequest, authContext.token]);

  return (
    <div>
      {videoDetails ? (
        <>
          <h1>{videoDetails.title}</h1>
          <p>Only viewable within organization</p>
          <video
            width="100%"
            height="auto"
            controls
            src={videoDetails.video_url}
          />
        </>
      ) : (
        <p>Loading video...</p>
      )}
    </div>
  );
};

export default VideoPlayer;
