import React from "react";

import { Toast } from "react-bootstrap";

const ToastError = ({ show, message, onClose }) => {
	return (
		<div style = {{ position: "fixed", top: 20, right: 20, zIndex: 9999 }}>
			<Toast
            show={show}
            onClose={onClose}
            delay={3000}
            autohide>
				<Toast.Header>
					<strong className="me-auto">Error</strong>
				</Toast.Header>
				<Toast.Body>{message}</Toast.Body>
			</Toast>
		</div>
	);
};

export default ToastError;
