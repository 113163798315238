import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../shared/context/auth-context";
import { useSearchParams } from "react-router-dom";
import {
	Spinner,
	Card,
	Button,
	Row,
	Form,
	Col,
	Container,
} from "react-bootstrap";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { registerWithInvite } from "../../services/authService";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config/firebase";
import Particles from "../../components/Particles";

const signUpSchema = Yup.object().shape({
	email: Yup.string()
		.email("Invalid email format")
		.required("Email is required"),
	password: Yup.string()
		.min(6, "Password must be at least 6 characters")
		.required("Password is required"),
	firstName: Yup.string().required("First name is required"),
	lastName: Yup.string().required("Last name is required"),
});

const InvitePage = () => {
	const authContext = useContext(AuthContext);
	const navigate = useNavigate();
	const [invite, setInvite] = useState(null);
	const [error, setError] = useState(null);
	const [searchParams] = useSearchParams();
	const inviteId = searchParams.get("id"); // Extract inviteId from URL
	const { isLoading, sendRequest } = useHttpClient();
	const [formValues, setFormValues] = useState({
		email: "",
		password: "",
		firstName: "",
		lastName: "",
	});

	const authSubmitHandler = async (values) => {
		const { email, password, firstName, lastName } = values;
		try {
			await createUserWithEmailAndPassword(auth, email, password); //firebase

            await registerWithInvite(
				auth.currentUser.accessToken,
				firstName,
				lastName,
				inviteId,
				sendRequest
			);

			authContext.login(auth.currentUser.uid, auth.currentUser.accessToken);
			navigate("/");
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		const fetchInvite = async () => {
			if (!inviteId) {
				console.log("No invite ID found");
				setError("Invalid invite link");
				return;
			}

			try {
				console.log("Fetching invite data");
				const response = await sendRequest(`/api/invite/${inviteId}`, "GET");
				setInvite(response.data);
				setFormValues((prevValues) => ({
					...prevValues,
					email: response.data.email,
					firstName: response.data.firstName,
				}));
			} catch (err) {
				setError("Failed to fetch invite data");
			}
		};

		fetchInvite();
	}, [inviteId, sendRequest]);

	if (isLoading) {
		return <Spinner animation="border" role="status" />;
	}

	if (error) {
		return <p>{error}</p>;
	}

	return (
		<React.Fragment>
			<Particles className="absolute inset-0 z-0" quantity={100} ease={100} color="#1c004b" enable={false} />
			{invite ? (
				<Container>
					<Row className="align-items-center" style={{ height: "100vh" }}>
						<Col xs={12} md={6}>
                        <Row>
							<img
								src="/logo.png"
								alt="Ratava Logo"
								style={{ maxWidth: "100%", maxHeight: "400px" }}
							></img>
                            </Row>
                            <Row>
                                <h3 style={{
                                    textAlign: "center",
                                    marginTop: "2rem",
                                }}>
                                    Welcome to the future, {invite.firstName}
                                </h3>
                                <p style={{ textAlign: "center" }}>
                                    You are invited to join the {invite.orgName} organization!
                                </p>
                            </Row>
						</Col>
						<Col xs={12} md={6}>
							<Card style={{ width: "18rem", margin: "0 auto", backgroundColor: "rgba(255, 255, 255, 0.5)", }}>
								<Card.Body>
									<Formik
										initialValues={formValues}
										validationSchema={signUpSchema}
										onSubmit={authSubmitHandler}
										enableReinitialize={true}
									>
										{({
											handleSubmit,
											values,
											touched,
											errors,
											isValid,
											dirty,
										}) => (
											<FormikForm noValidate onSubmit={handleSubmit}>
												<Row className="mb-3">
													<Form.Group md="6" controlId="firstName">
														<Form.Label>First Name</Form.Label>
														<Field
															as={Form.Control}
															value={values.firstName}
															type="text"
															name="firstName"
															isInvalid={
																touched.firstName && !!errors.firstName
															}
															isValid={touched.firstName && !errors.firstName}
														/>
														<ErrorMessage
															name="firstName"
															component="div"
															className="text-danger"
														/>
													</Form.Group>
												</Row>
												<Row className="mb-3">
													<Form.Group md="6" controlId="lastName">
														<Form.Label>Last Name</Form.Label>
														<Field
															as={Form.Control}
															value={values.lastName}
															type="text"
															name="lastName"
															isInvalid={touched.lastName && !!errors.lastName}
															isValid={touched.lastName && !errors.lastName}
														/>
														<ErrorMessage
															name="lastName"
															component="div"
															className="text-danger"
														/>
													</Form.Group>
												</Row>
												<Row className="mb-3">
													<Form.Group md="6" controlId="email">
														<Form.Label>E-Mail</Form.Label>
														<Field
															as={Form.Control}
															value={values.email}
															type="email"
															name="email"
															isInvalid={touched.email && !!errors.email}
															isValid={touched.email && !errors.email}
														/>
														<ErrorMessage
															name="email"
															component="div"
															className="text-danger"
														/>
													</Form.Group>
												</Row>
												<Row className="mb-3">
													<Form.Group md="6" controlId="password">
														<Form.Label>Password</Form.Label>
														<Field
															as={Form.Control}
															value={values.password}
															type="password"
															name="password"
															isInvalid={touched.password && !!errors.password}
															isValid={touched.password && !errors.password}
														/>
														<ErrorMessage
															name="password"
															component="div"
															className="text-danger"
														/>
													</Form.Group>
												</Row>
                                                <Button
                                                    className="submit-button"
                                                    variant="primary"
                                                    type="submit"
                                                    disabled={!(dirty && isValid)}
                                                >
                                                    Sign Up
                                                </Button>

											</FormikForm>
										)}
									</Formik>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			) : (
				<p>No invite found.</p>
			)}
		</React.Fragment>
	);
};

export default InvitePage;
