import React, { useState, useEffect, useContext } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { Form, Button, Spinner } from "react-bootstrap";
import ToastError from "../../shared/elements/ToastError";

const MakeAdmin = () => {
    const authContext = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await sendRequest(
                    "/api/users",
                    "GET",
                    null,
                    {
                        Authorization: "Bearer " + authContext.token,
                    }
                );
                setUsers(response.data);
            } catch (err) {
                console.error('Error fetching users:', err);
            }
        };

        fetchUsers();
    }, [sendRequest, authContext.token]);

    const handleMakeAdmin = async () => {
        try {
            const response = await sendRequest(
                "/api/make-admin",
                "POST",
                JSON.stringify({ userId: selectedUser }),
                {
                    Authorization: "Bearer " + authContext.token,
                    "Content-Type": "application/json",
                }
            );
            alert(response.data.message);
        } catch (err) {
            console.error('Error making user admin:', err);
        }
    };

    return (
        <div>
            <ToastError show={!!error} message={error} onClose={clearError} />
            {isLoading && <Spinner animation="border" />}
            <Form>
                <Form.Group controlId="userSelect">
                    <Form.Label>Make a user Super Admin</Form.Label>
                    <Form.Control as="select" value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                        <option value="">Select a user</option>
                        {users.map((user) => (
                            <option key={user.id} value={user.id}>
                                {user.firstName} {user.lastName} ({user.email})
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Button variant="primary" onClick={handleMakeAdmin} disabled={!selectedUser}>
                    Make Admin
                </Button>
            </Form>
        </div>
    );
};

export default MakeAdmin;
