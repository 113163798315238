import React, { useState } from "react";
import { auth } from "../../config/firebase"; // Your Firebase configuration file
import { sendPasswordResetEmail } from "firebase/auth";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
	Button,
	Form,
	Container,
	Row,
	Col,
	Card,
	Alert,
} from "react-bootstrap";

const forgotPasswordSchema = Yup.object().shape({
	email: Yup.string()
		.email("Invalid email format")
		.required("Email is required"),
});

const ForgotPassword = () => {
	const [emailSent, setEmailSent] = useState(false);
	const [error, setError] = useState("");

	const handlePasswordReset = async (values) => {
		try {
			await sendPasswordResetEmail(auth, values.email);
			setEmailSent(true);
			setError("");
		} catch (error) {
			console.error(error);
			setError(error.message);
		}
	};

	return (
		<Container>
			<Row className="align-items-center" style={{ height: "100vh" }}>
				<Col xs={12} md={6} className="mx-auto">
					<Card style={{ padding: "20px" }}>
						<Card.Body>
							<Card.Title>Forgot Password</Card.Title>
							{emailSent && (
								<Alert variant="success">
									Password reset link sent! Please check your email.
								</Alert>
							)}
							{error && <Alert variant="danger">{error}</Alert>}
							<Formik
								initialValues={{ email: "" }}
								validationSchema={forgotPasswordSchema}
								onSubmit={handlePasswordReset}
							>
								{({ handleSubmit, isValid, dirty }) => (
									<FormikForm noValidate onSubmit={handleSubmit}>
										<Row className="mb-3">
											<Form.Group as={Col} controlId="email">
												<Form.Label>Email Address</Form.Label>
												<Field
													as={Form.Control}
													type="email"
													name="email"
													placeholder="Enter your email"
												/>
												<ErrorMessage
													name="email"
													component="div"
													className="text-danger"
												/>
											</Form.Group>
										</Row>
										<Row>
											<Col
												className="mb-3"
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center",
												}}
											>
												<Button
													type="submit"
													variant="primary"
													disabled={!(isValid && dirty)}
												>
													Send Reset Link
												</Button>
												<Button
													variant="secondary"
													onClick={() => {
														window.location.href = "/";
													}}
												>
													Back to Login
												</Button>
											</Col>
										</Row>
									</FormikForm>
								)}
							</Formik>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default ForgotPassword;
