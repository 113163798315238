import React from "react";
import { Card, Button } from "react-bootstrap";
import { Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";

const renderStatus = (status) => {
    let statusElement;

    if (status === "pending") {
        statusElement = <Spinner animation="border" size="sm" variant="warning" />;
    } else if (status === "processing") {
        statusElement = <Spinner animation="border" size="sm" variant="success" />;
    } else if (status === "completed") {
        statusElement = (
            <span style={{ display: "inline-block", width: "10px", height: "10px", backgroundColor: "green", borderRadius: "50%" }}></span>
        );
    } else if (status === "failed") {
        statusElement = (
            <span style={{ display: "inline-block", width: "10px", height: "10px", backgroundColor: "red", borderRadius: "50%" }}></span>
        );
    }

    return (
        <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip>{status}</Tooltip>}
        >
            <div style={{ display: "inline-block" }}>{statusElement}</div>
        </OverlayTrigger>
    );
};

const VideoCard = ({ video, onShowVideo }) => {
	return (
		<Card 
            className={"card-clickable"}
            style={{ width: "18rem", marginBottom: "20px" }}
            onClick={() => onShowVideo(video)}
            >
			<Card.Body>
				<Card.Img
					src={video.thumbnail_url}
					alt={video.title}
                    //set max height for the image
                    style={{ 
                        //fix the dimensions of the image
                        maxHeight: "9rem",
                        objectFit: "cover",
                        objectPosition: "top"
                    }}
				/>
				<Card.Title>{video.title}</Card.Title>
				<Card.Text>
					<p>{renderStatus(video.status)} {video.status}</p> 
				</Card.Text>
				<Button variant="primary" onClick={() => onShowVideo(video)}>
					View Details
				</Button>
			</Card.Body>
		</Card>
	);
};

export default VideoCard;
