import React, { useContext } from 'react';
import { AuthContext } from '../../shared/context/auth-context';

const OrganizationSelector = () => {
  const {
    organizations,
    currentOrganization,
    switchOrganization,
    isSuperAdmin,
  } = useContext(AuthContext);

  // Determine if the dropdown should be displayed
  const showDropdown = isSuperAdmin || organizations.length > 1;

  const handleChange = (event) => {
    const orgId = event.target.value === 'none' ? null : event.target.value;
    switchOrganization(orgId);
  };

  if (!showDropdown) {
    return null; // Don't render anything if the dropdown shouldn't be displayed
  }

  return (
    <div>
      {isSuperAdmin && <p>You are a Super Admin.</p>}
      <label htmlFor="organization-select">View as:</label>
      <select
        id="organization-select"
        value={currentOrganization ? currentOrganization.orgId : 'none'}
        onChange={handleChange}
      >
        <option value="none">---None---</option>
        {organizations.map((org) => (
          <option key={org.orgId} value={org.orgId}>
            {org.orgName} ({org.role})
          </option>
        ))}
      </select>
    </div>
  );
};

export default OrganizationSelector;
