import React, { useState, useEffect, useRef, useContext } from "react";
import {
	createSession,
	startSession,
	sendIceCandidate,
	endSession,
	sendText,
} from "../services/sessionService";

import { AuthContext } from "../shared/context/auth-context";
import { useHttpClient } from "../shared/hooks/http-hook";

import ToastError from "../shared/elements/ToastError";

import { Button, Spinner } from "react-bootstrap";
//import { get } from "firebase/database";

function SessionManager() {
	const [sessionId, setSessionId] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [pc, setPc] = useState(null);
	const [textToSend, setTextToSend] = useState("");
	const [chatHistory, setChatHistory] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const videoRef = useRef();
	const auth = useContext(AuthContext);
	const { sendRequest } = useHttpClient();
	const textareaRef = useRef(null);
	const chatContainerRef = useRef(null);
	//const { isLoading, error, sendRequest, clearError } = useHttpClient();

	useEffect(() => {
		return () => {
			if (pc) {
				pc.close();
			}
		};
	}, [pc]);

	useEffect(() => {
		if (chatContainerRef.current) {
			chatContainerRef.current.scrollTop =
				chatContainerRef.current.scrollHeight;
		}
	}, [chatHistory]);

	const cleanup = async (manualCleanup = false) => {
		if (pc) {
			pc.close();
			setPc(null);
		}
		if (sessionId) {
			try {
				await endSession(sessionId, auth.token);
				setSessionId(null);
			} catch (error) {
				console.error("Error ending HeyGen session:", error);
			}
		}
		if (manualCleanup) {
			setErrorMessage(null);
		}
	};

	const startSessionHandler = async () => {
		try {
			// Create a new Peer Connection with ICE servers from HeyGen

			const session = await createSession(auth.token, sendRequest);
			const peerConnection = new RTCPeerConnection({
				iceServers: session.ice_servers,
			});
			setPc(peerConnection);

			// Set the remote video stream when received
			peerConnection.ontrack = (event) => {
				if (videoRef.current) {
					videoRef.current.srcObject = event.streams[0];
				}
			};

			// Ensure type and sdp are not null
			const { type, sdp } = session;
			if (type && sdp) {
				await peerConnection.setRemoteDescription(
					new RTCSessionDescription({ type, sdp })
				);
			} else {
				throw new Error("Invalid type or sdp");
			}

			peerConnection.onicecandidate = async (event) => {
				if (event.candidate) {
					try {
						await sendIceCandidate(
							session.session_id,
							event.candidate,
							auth.token
						);
					} catch (error) {
						console.error("Error sending ICE candidate:", error);
						//setErrorMessage("Error sending ICE candidate: " + error.message);
					}
				}
			};

			const answer = await peerConnection.createAnswer();
			await peerConnection.setLocalDescription(answer);

			// Start session with the local SDP answer
			await startSession(session.session_id, answer, auth.token, sendRequest);
			setSessionId(session.session_id);
		} catch (error) {
			console.error("Failed to start session:", error);
			const errorMessage =
				error instanceof Error ? error.message : JSON.stringify(error);
			console.log("Failed to start session: " + errorMessage);
			setErrorMessage("Failed to start session: " + errorMessage);
		}
	};

	const endSessionHandler = async () => {
		try {
			await cleanup(true);
		} catch (error) {
			console.error("Failed to end session:", error);
			const errorMessage =
				error instanceof Error ? error.message : JSON.stringify(error);
			setErrorMessage("Failed to end session: " + errorMessage);
		}
	};

	const handleInputChange = (e) => {
		setTextToSend(e.target.value);
		const textarea = textareaRef.current;
		if (textarea) {
			textarea.style.height = "auto";
			textarea.style.height = textarea.scrollHeight + "px";
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			textHandler("prompt");
		}
	};

	const textHandler = async (type) => {
		if (!sessionId) {
			setErrorMessage("Please start a session first");
			return;
		}
		if (!textToSend.trim()) {
			setErrorMessage("Please enter a text to send");
			return;
		}
		setIsLoading(true);
		try {
			const response = await sendText(
				sessionId,
				{ text: textToSend, type },
				auth.token
			);
			if (type === "prompt") {
				setChatHistory((prev) => [
					...prev,
					{ text: textToSend, sender: "user" },
					{ text: response, sender: "agent" },
				]);
			} else {
				// Handle repeat type by just logging it to the history without showing it
				setChatHistory((prev) => [
					...prev,
					{ text: response, sender: "agent" },
				]);
			}
			setTextToSend("");
		} catch (error) {
			setErrorMessage("Failed to send text: " + error.message);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="session-manager">
			<ToastError
				show={!!errorMessage}
				message={errorMessage}
				onClose={() => setErrorMessage(null)}
			/>
			<div className="controls">
				<h2>Session Manager</h2>
				<Button onClick={startSessionHandler}>Start Session</Button>
				<Button onClick={endSessionHandler} disabled={!sessionId}>
					End Session
				</Button>
			</div>
			{pc && (
				<div className="content">
					<div className="chat-box">
						<h3>Chat</h3>
						<div ref={chatContainerRef} className="chat-history">
							{chatHistory.map((message, index) => (
								<div
									key={index}
									className={`message ${message.sender}`}
									dangerouslySetInnerHTML={{ __html: message.text }}
								/>
							))}
							{isLoading && <div className="message loading">Loading...</div>}
						</div>
						{sessionId && (
							<div className="input-container">
								<textarea
									ref={textareaRef}
									className="input-textarea"
									value={textToSend}
									onChange={handleInputChange}
									onKeyPress={handleKeyPress}
									placeholder="Type your message here..."
									rows="1"
								/>
								<Button
									className="submit-button"
									onClick={() => textHandler("prompt")}
									disabled={isLoading}
								>
									{isLoading ? (
										<Spinner animation="border" size="sm" />
									) : (
										"Send"
									)}
								</Button>
								<Button
									className="submit-button"
									onClick={() => textHandler("repeat")}
									disabled={isLoading}
								>
									Repeat Text
								</Button>
							</div>
						)}
					</div>
					<div className="video-box">
						{pc && (
							<video ref={videoRef} className="stream" autoPlay playsInline />
						)}
					</div>
				</div>
			)}
		</div>
	);
}
//{errorMessage && <p className="error-message">{errorMessage}</p>}
export default SessionManager;
