import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
	initializeAppCheck,
	ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
//import {getAnalytics} from 'firebase/analytics';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

console.log(process.env.REACT_APP_ENV);

if (process.env.REACT_APP_ENV === "development") {
	console.log("Development mode");
	window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}
initializeAppCheck(app, {
	provider: new ReCaptchaEnterpriseProvider(
		process.env.REACT_APP_RECAPTCHA_SITE_KEY
	),
	isTokenAutoRefreshEnabled: true,
});

export const auth = getAuth(app);
//const analytics = getAnalytics(app);
