import React from "react";
import { Card, Placeholder } from "react-bootstrap";
const User = (props) => {
    if(!props.user) {
        return (
            <Card>
                <Card.Body>
                    <Placeholder as={Card.Title} animation="glow">
                        <Placeholder xs={2} />
                    </Placeholder>
                    <Placeholder as={Card.Text} animation="glow">
                        <Placeholder xs={4} />
                    </Placeholder>
                </Card.Body>
            </Card>
        );
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title>{props.user.firstName} {props.user.lastName}</Card.Title>
                <Card.Text>
                    {props.user.email}
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default User;