import React, { useEffect, useState, useContext, useCallback } from "react";

import { AuthContext } from "../../shared/context/auth-context";
import { auth } from "../../config/firebase";
import { sendEmailVerification } from "firebase/auth";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { Button, Alert } from "react-bootstrap";
import ToastError from "../../shared/elements/ToastError";

import UpdateUser from "../components/UpdateUser";
import Me from "../components/Me";
import OrganizationSelector from "../components/OrganizationSelector";

const Settings = () => {
	const { error, sendRequest, clearError } = useHttpClient();
	const [loadedUserInfo, setLoadedUserInfo] = useState();
	const [emailVerificationSent, setEmailVerificationSent] = useState(false);
	const authCon = useContext(AuthContext);

	const fetchUserData = useCallback(async () => {
		try {
			const responseData = await sendRequest(
				"/api/user",
				"GET",
				null,
				{},
				authCon.token
			);
			setLoadedUserInfo(responseData.data);
		} catch (err) {}
	}, [sendRequest, authCon.token]);

	useEffect(() => {
		fetchUserData();
	}, [fetchUserData]);

	const sendVerificationEmail = async () => {
		if (auth.currentUser && !auth.currentUser.emailVerified) {
			try {
				await sendEmailVerification(auth.currentUser);
				setEmailVerificationSent(true);
			} catch (err) {
				console.log(err);
			}
		}
	};

	return (
		<div data-tour-target="settings-content">
			<React.Fragment>
				<ToastError show={!!error} message={error} onClose={clearError} />
				<Me loadedUserInfo={loadedUserInfo} />
				<UpdateUser onUserUpdated={fetchUserData} />
				<OrganizationSelector />
				{auth.currentUser && !auth.currentUser.emailVerified && (
					<div className="text-center mt-3">
						<Button
							variant="primary"
							onClick={sendVerificationEmail}
							disabled={emailVerificationSent}
						>
							{emailVerificationSent
								? "Verification Email Sent"
								: "Verify Email"}
						</Button>
					</div>
				)}
				{emailVerificationSent && (
					<Alert variant="success" className="mt-3">
						Verification email sent successfully! Please check your inbox.
					</Alert>
				)}
				<Button
					variant="danger"
					className="mx-auto d-block"
					style={{ width: "18rem" }}
					onClick={authCon.logout}
					href="/"
				>
					Logout
				</Button>
			</React.Fragment>
		</div>
	);
};

export default Settings;
