import React from "react";
import MakeAdmin from "../components/MakeAdmin"
import AddOrg from "../components/AddOrg";
import OrgList from "../components/OrgList";
import MakeOrgAdmin from "../components/MakeOrgAdmin";
import InviteUser from "../components/InviteUser";

const AdminSettings = () => {
    return ( 
        <React.Fragment>
            <div style={{ border: "1px solid black", padding: "10px", marginBottom: "10px" }}>
                <MakeOrgAdmin />
            </div>
            <div style={{ border: "1px solid black", padding: "10px", marginBottom: "10px" }}>
                <MakeAdmin />
            </div>
            <div style={{ border: "1px solid black", padding: "10px", marginBottom: "10px" }}>
                <AddOrg />
            </div>
            <div style={{ border: "1px solid black", padding: "10px", marginBottom: "10px" }}>
                <OrgList />
            </div>
            <div style={{ border: "1px solid black", padding: "10px", marginBottom: "10px" }}>
                <InviteUser />
            </div>
        </React.Fragment>
    );
};

export default AdminSettings;