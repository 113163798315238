import React, { useContext, useEffect, useState } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import ToastError from "../../shared/elements/ToastError";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import { Button, Row, Form } from "react-bootstrap";
import * as Yup from "yup";

const clientSchema = Yup.object().shape({
	clientName: Yup.string().required("Client name is required"),
	description: Yup.string().required("Description is required"),
	orgName: Yup.string().required("Organization is required"), // New validation for orgName
});

const ClientEditor = ({ client, onClientUpdated = () => {} }) => {
	const authContext = useContext(AuthContext);
	const { error, sendRequest, clearError } = useHttpClient();
	const [organizations, setOrganizations] = useState([]);

	useEffect(() => {
		if (authContext.isSuperAdmin || authContext.orgRole === "admin") {
			const fetchOrganizations = async () => {
				try {
					const response = await sendRequest(
						"/api/organizations",
						"GET",
						null,
						{
							Authorization: "Bearer " + authContext.token,
						}
					);
					setOrganizations(response.data);
				} catch (err) {
					console.error("Error fetching organizations:", err);
				}
			};

			fetchOrganizations();
		}
	}, [
		sendRequest,
		authContext.token,
		authContext.isSuperAdmin,
		authContext.orgRole,
	]);

	const initialValues = {
		clientName: client ? client.clientName : "",
		description: client ? client.description : "",
		orgName: client ? client.orgName : "", // New orgName field
	};

	const submitHandler = async (values, { setSubmitting, resetForm }) => {
		try {
			const payload = {
				...values,
			};

			if (client) {
				await sendRequest(
					`/api/clients/${client.id}`,
					"PUT",
					JSON.stringify(payload),
					{
						"Content-Type": "application/json",
						Authorization: "Bearer " + authContext.token,
					}
				);
			} else {
				await sendRequest("/api/clients", "POST", JSON.stringify(payload), {
					"Content-Type": "application/json",
					Authorization: "Bearer " + authContext.token,
				});
			}
			onClientUpdated();
			resetForm();
		} catch (error) {
			console.error(error);
		} finally {
			setSubmitting(false);
		}
	};

	const handleDeleteClient = async () => {
		if (!client) return;
		try {
			await sendRequest(`/api/clients/${client.id}`, "DELETE", null, {
				Authorization: "Bearer " + authContext.token,
			});
			onClientUpdated();
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<React.Fragment>
			<ToastError show={!!error} message={error} onClose={clearError} />
			<Formik
				initialValues={initialValues}
				validationSchema={clientSchema}
				onSubmit={submitHandler}
				enableReinitialize={false} // Disable reinitialization to prevent reset on org change
			>
				{(formikProps) => (
					<FormikForm noValidate onSubmit={formikProps.handleSubmit}>
						<Row className="mb-3">
							<Form.Group md="6" controlId="clientName">
								<Form.Label>Client Name</Form.Label>
								<Field as={Form.Control} type="text" name="clientName" />
								<ErrorMessage
									name="clientName"
									component="div"
									className="text-danger"
								/>
							</Form.Group>
						</Row>
						<Row className="mb-3">
							<Form.Group md="6" controlId="description">
								<Form.Label>Description</Form.Label>
								<Field as={Form.Control} type="text" name="description" />
								<ErrorMessage
									name="description"
									component="div"
									className="text-danger"
								/>
							</Form.Group>
						</Row>
						{(authContext.isSuperAdmin ||
							(authContext.orgRole === "admin" &&
								organizations.length > 1)) && (
							<Row className="mb-3">
								<Form.Group controlId="orgName">
									<Form.Label>Organization</Form.Label>
									<Form.Control
										as="select"
										value={formikProps.values.orgName} // Bind directly to Formik's value
										onChange={(e) =>
											formikProps.setFieldValue("orgName", e.target.value)
										} // Update via Formik's setFieldValue
									>
										<option value="">Select an organization</option>
										{organizations.map((org) => (
											<option key={org.name} value={org.name}>
												{org.name}
											</option>
										))}
									</Form.Control>
									<ErrorMessage
										name="orgName"
										component="div"
										className="text-danger"
									/>
								</Form.Group>
							</Row>
						)}
						<Button
							className="submit-button"
							variant="primary"
							type="submit"
							disabled={formikProps.isSubmitting || !formikProps.dirty}
						>
							{client ? "Update" : "Create"}
						</Button>

						<Button
							className="delete-button"
							variant="danger"
							onClick={handleDeleteClient}
							style={{ marginLeft: "10px" }}
						>
							Delete
						</Button>
					</FormikForm>
				)}
			</Formik>
		</React.Fragment>
	);
};

export default ClientEditor;
