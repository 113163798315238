import React from "react";
import User from "./User";


const Me = ({ loadedUserInfo}) => {
	return (
		<React.Fragment>
			<User user={loadedUserInfo} />
		</React.Fragment>
	);
};

export default Me;
