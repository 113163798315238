import React, { useEffect, useState, useContext, useCallback } from "react";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import ToastError from "../../shared/elements/ToastError";
import AvatarList from "../components/AvatarList";
import { Toast } from "react-bootstrap";

const AvatarViewer = () => {
    const { error, sendRequest, clearError } = useHttpClient();
    const [loadedAvatarInfo, setLoadedAvatarInfo] = useState();
    const [showToast, setShowToast] = useState(false);
    const auth = useContext(AuthContext);

    const fetchAvatarData = useCallback(async () => {
        try {
            const responseData = await sendRequest(
                "/api/avatars",
                "GET",
                null,
                {
                    Authorization: "Bearer " + auth.token,
                    'x-org-id': auth.currentOrganization ? auth.currentOrganization.id : '',
                }
            );
            setLoadedAvatarInfo(responseData.data);
        } catch (err) {
            console.error(err);
        }
    }, [auth.currentOrganization, sendRequest, auth.token]);

    useEffect(() => {
        fetchAvatarData();
    }, [fetchAvatarData]);

    const handleShowToast = () => {
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);
    };

    return (
        <React.Fragment>
            <ToastError show={!!error} message={error} onClose={clearError} />
            <AvatarList 
                loadedAvatarInfo={loadedAvatarInfo} 
                onShowToast={handleShowToast}
            />
            <div style={{ position: "fixed", top: 20, right: 20, zIndex: 1050 }}>
                <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">Success</strong>
                        <small>Just now</small>
                    </Toast.Header>
                    <Toast.Body>Update successful!</Toast.Body>
                </Toast>
            </div>
        </React.Fragment>
    );
};

export default AvatarViewer;
