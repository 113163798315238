import { useCallback, useContext } from 'react';
import { auth } from "../../config/firebase";
import { AuthContext } from "../context/auth-context";

export const useRefreshToken = () => {
    const authContext = useContext(AuthContext); 

    const refreshAccessToken = useCallback(async () => {
        try {
            //console.log(auth.currentUser); //GET LOCAL USER DATA BEFORE AUTH.CURRENTUSER
            const idToken = await auth.currentUser.getIdToken(true);//Forced refresh the token if there is an auth.currentUser
            
            const storedData = JSON.parse(localStorage.getItem("userData"));
            if (storedData) {
                storedData.token = idToken;
                localStorage.setItem("userData", JSON.stringify(storedData));
                authContext.login(storedData.userId, idToken);
            }
            console.log("Token refreshed");
            return idToken;
        } catch (error) {
            console.error("Error refreshing token: ", error);
            throw error;
        }
    }, [authContext]);

    return refreshAccessToken;
};

//  Problem: auth.currentUser is null so firebase funciton doesn't work
//  
//  Flow should be
//  login with local user data
//
//
//
//
//


//Potential Solution:

/*import { useCallback, useContext } from 'react';
import { auth } from "../../config/firebase";
import { AuthContext } from "../context/auth-context";

export const useRefreshToken = () => {
    const authContext = useContext(AuthContext);

    const refreshAccessToken = useCallback(async () => {
        try {
            if (!auth.currentUser) {
                console.warn("No current user. Attempting to re-authenticate using stored token.");
                const storedData = JSON.parse(localStorage.getItem("userData"));
                if (storedData && storedData.token) {
                    await auth.signInWithCustomToken(storedData.token);
                } else {
                    throw new Error("No stored token available for re-authentication.");
                }
            }

            const idToken = await auth.currentUser.getIdToken(true); // Forced refresh the token if there is an auth.currentUser
            const storedData = JSON.parse(localStorage.getItem("userData"));
            if (storedData) {
                storedData.token = idToken;
                localStorage.setItem("userData", JSON.stringify(storedData));
                authContext.login(storedData.userId, idToken);
            }
            console.log("Token refreshed");
            return idToken;
        } catch (error) {
            console.error("Error refreshing token: ", error);
            throw error;
        }
    }, [authContext]);

    return refreshAccessToken;
};
*/
