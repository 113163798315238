import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase";
import {
	verifyPasswordResetCode,
	confirmPasswordReset,
	applyActionCode,
} from "firebase/auth";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
	Button,
	Form,
	Container,
	Row,
	Col,
	Card,
	Alert,
	Spinner,
} from "react-bootstrap";

const AuthAction = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [error, setError] = useState("");
	const [actionMode, setActionMode] = useState(null);
	const [oobCode, setOobCode] = useState(null);
	const [emailVerified, setEmailVerified] = useState(false);
	const [passwordReset, setPasswordReset] = useState({
		newPassword: "",
		success: false,
	});

	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const mode = query.get("mode");
		const oobCode = query.get("oobCode");

		setActionMode(mode);
		setOobCode(oobCode);

		if (mode === "resetPassword") {
			verifyPasswordResetCode(auth, oobCode)
				.then(() => setPasswordReset((prev) => ({ ...prev, valid: true })))
				.catch((err) => setError(err.message));
		} else if (mode === "verifyEmail") {
			applyActionCode(auth, oobCode)
				.then(() => setEmailVerified(true))
				.catch((err) => setError(err.message));
		} else if (mode === "recoverEmail") {
			applyActionCode(auth, oobCode)
				.then(() => navigate("/"))
				.catch((err) => setError(err.message));
		} else {
			setError("Invalid action mode");
		}
	}, [location.search, navigate]);

	const handlePasswordReset = (values) => {
		confirmPasswordReset(auth, oobCode, values.newPassword)
			.then(() => setPasswordReset((prev) => ({ ...prev, success: true })))
			.catch((err) => setError(err.message));
	};

	if (error) {
		return <Alert variant="danger">{error}</Alert>;
	}

	if (actionMode === "verifyEmail" && emailVerified) {
		return <Alert variant="success">Email verified successfully</Alert>;
	}

	if (actionMode === "resetPassword" && passwordReset.valid) {
		return (
			<Container>
				<Row className="justify-content-center">
					<Col md={6}>
						<Card>
							<Card.Body>
								<h2 className="text-center mb-4">Reset Password</h2>
								{passwordReset.success ? (
									<div>
										<Alert variant="success">
											Password changed successfully
										</Alert>
										<Button
											variant="primary"
											onClick={() => navigate("/")}
											className="w-100 mt-3"
										>
											Back to Home
										</Button>
									</div>
								) : (
									<Formik
										initialValues={passwordReset}
										validationSchema={Yup.object({
											newPassword: Yup.string()
												.min(6, "Password must be at least 6 characters")
												.required("Password is required"),
										})}
										onSubmit={handlePasswordReset}
									>
										{({ handleSubmit, touched, errors, isValid, dirty }) => (
											<FormikForm noValidate onSubmit={handleSubmit}>
												<Form.Group id="newPassword">
													<Form.Label>New Password</Form.Label>
													<Field
														type="password"
														name="newPassword"
														className="form-control"
														isInvalid={
															touched.newPassword && !!errors.newPassword
														}
														isValid={touched.newPassword && !errors.newPassword}
													/>
													<ErrorMessage
														name="newPassword"
														component="div"
														className="text-danger"
													/>
												</Form.Group>
												<Button
													type="submit"
													className="w-100 mt-3"
													disabled={!isValid || !dirty || !touched.newPassword}
												>
													Reset Password
												</Button>
											</FormikForm>
										)}
									</Formik>
								)}
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		);
	}

	return <Spinner animation="border" />;
};

export default AuthAction;
