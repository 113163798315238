import React, { createContext, useState, useEffect, useCallback } from "react";
import { auth } from "../../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useHttpClient } from "../hooks/http-hook";

export const AuthContext = createContext({
	isLoggedIn: false,
	userId: null,
	token: null,
	isSuperAdmin: false,
	isInOrganization: false,
	isAuthReady: false,
	orgRole: null,
	orgName: null,
	memberships: [],
	organizations: [],
	currentOrganization: null,

	login: () => {},
	logout: () => {},
});

export const AuthProvider = ({ children }) => {
	const [token, setToken] = useState(null);
	const [userId, setUserId] = useState(null);
	const [isSuperAdmin, setIsSuperAdmin] = useState(false);
	const [isAuthReady, setIsAuthReady] = useState(false);
	const [isInOrganization, setIsInOrganization] = useState(false);
	const [orgRole, setOrgRole] = useState(null);
	const [orgName, setOrgName] = useState(null);
	const [memberships, setMemberships] = useState([]);
	const [organizations, setOrganizations] = useState([]);
	const [currentOrganization, setCurrentOrganization] = useState(null);

	const { sendRequest } = useHttpClient();

	const login = useCallback(
		async (uid, token) => {
			console.log("Logging in with uid:", uid);
			setToken(token);
			setUserId(uid);
			localStorage.setItem(
				"userData",
				JSON.stringify({ userId: uid, token: token })
			);

			if (auth.currentUser) {
				try {
					const response = await sendRequest("/api/roles", "GET", null, {
						Authorization: "Bearer " + token,
					});
					const data = response.data;

					setIsSuperAdmin(data.isSuperAdmin);
					setIsInOrganization(data.isInOrganization);
					setMemberships(data.memberships);

					if (data.isInOrganization && data.memberships.length > 0) {
						setOrganizations(data.memberships);
						// Use first memebership to set orgRole and orgName
						const defaultOrg = data.memberships[0];
						setOrgRole(defaultOrg.role);
						setOrgName(defaultOrg.orgName);

                        //setOrgRole(null);
						//setOrgName(null);
					} else {
						setOrgRole(null);
						setOrgName(null);
						setOrganizations([]);
						setCurrentOrganization(null);
					}
				} catch (error) {
					console.error("Error fetching user roles from backend: ", error);
					setIsSuperAdmin(false);
					setIsInOrganization(false);
					setOrgRole(null);
					setOrgName(null);
					setOrganizations([]);
					setCurrentOrganization(null);
				}
			} else {
				setIsSuperAdmin(false);
				setIsInOrganization(false);
				setOrgRole(null);
				setOrgName(null);
				setOrganizations([]);
				setCurrentOrganization(null);
			}

			setIsAuthReady(true);
		},
		[sendRequest]
	);

	const logout = useCallback(() => {
		setToken(null);
		setUserId(null);
		setIsSuperAdmin(false);
		setIsInOrganization(false);
		setOrgRole(null);
		setOrgName(null);
		localStorage.removeItem("userData");
		setIsAuthReady(true);
	}, []);

	const forceTokenRefreshAndLogin = useCallback(
		async (storedData) => {
			const user = auth.currentUser;
			if (user) {
				try {
					const newToken = await user.getIdToken(true);
					console.log("Token refreshed on page load");
					await login(user.uid, newToken);
				} catch (error) {
					console.error("Error refreshing token:", error);
					await login(storedData.userId, storedData.token);
				}
			} else {
				logout();
			}
		},
		[login, logout]
	);

	const switchOrganization = (orgId) => {
		if (orgId === null || orgId === "none") {
			setCurrentOrganization(null);
			setOrgRole(null);
			setOrgName(null);
		} else {
			const selectedOrg = memberships.find((org) => org.orgId === orgId);
			if (selectedOrg) {
				setCurrentOrganization(selectedOrg);
				setOrgRole(selectedOrg.role);
				setOrgName(selectedOrg.orgName);
				console.log("Switched to organization:", selectedOrg);
			} else {
				console.error("Organization not found:", orgId);
			}
		}
	};

	useEffect(() => {
		const storedData = JSON.parse(localStorage.getItem("userData"));
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			if (user && storedData && storedData.token) {
				forceTokenRefreshAndLogin(storedData);
			} else {
				setIsAuthReady(true);
			}
		});
		return () => unsubscribe();
	}, [forceTokenRefreshAndLogin]);

	return (
		<AuthContext.Provider
			value={{
				isLoggedIn: !!token,
				userId: userId,
				token: token,
				isSuperAdmin: isSuperAdmin,
				isInOrganization: isInOrganization,
				isAuthReady: isAuthReady,
				orgRole: orgRole,
				orgName: orgName,
				memberships: memberships,
				organizations: organizations,
				currentOrganization: currentOrganization,
				switchOrganization: switchOrganization,
				login: login,
				logout: logout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
