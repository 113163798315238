import React from "react";
import AddAvatar from "../components/AddAvatar";
import AvatarViewer from "../../workspace/pages/AvatarViewer";

const AdminSettings = () => {
    return ( 
        <React.Fragment>
            <AddAvatar />
            <p>Current Avatars in System</p>
            <AvatarViewer />
            
        </React.Fragment>
        
    );
};

export default AdminSettings;