import axios from "axios";
const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api`;

// Create a new session to retrieve server's offer SDP
export const createSession = async (token, sendRequest) => {
	try {
		const response = await sendRequest(
			"/api/new-session",
			"POST",
			JSON.stringify({ quality: "low"}),
			{
				"Content-Type": "application/json",
			},
			token
		);
		return response.data; // { sdp, session_id }
	} catch (error) {
		throw new Error(
			`Failed to create session: ${
				error.response ? error.response.data : error.message
			}`
		);
	}
};

// Start the session with the answer SDP
export const startSession = async (sessionId, sdp, token, sendRequest) => {
	try {
		const response = await sendRequest(
			"/api/start-session",
			"POST",
			JSON.stringify({
				session_id: sessionId,
				sdp: {
					type: sdp.type,
					sdp: sdp.sdp,
				}
			}),
			{
				"Content-Type": "application/json",
			},
			token
		);
		return response.data; // Expected to receive confirmation or additional session info
	} catch (error) {
		throw new Error(
			`Failed to start session: ${
				error.response ? error.response.data : error.message
			}`
		);
	}
};

// Send ICE candidate to the session
export const sendIceCandidate = async (sessionId, candidate, token) => {
	try {
		await axios.post(
			`${baseUrl}/ice-candidate/${sessionId}`,
			{
				candidate: candidate
			},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token,
				},
			}
		);
	} catch (error) {
		throw new Error(
			`Failed to send ICE candidate: ${
				error.response ? error.response.data : error.message
			}`
		);
	}
};

// End the session
export const endSession = async (sessionId, token) => {
	try {
		await axios.post(
			`${baseUrl}/end-session/${sessionId}`,
			{},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token,
				},
			}
		);
	} catch (error) {
		throw new Error(
			`Failed to end session: ${
				error.response ? error.response.data : error.message
			}`
		);
	}
};

export const sendText = async (sessionId, textData, token) => {
	try {
		const response = await axios.post(
			`${baseUrl}/text/${sessionId}`,
			{
				textData: textData
				// textData: {
				//     text: textData.text,
				//     type: textData.type
				// }
			},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token,
				},
			}
		);
		return response.data;
	} catch (error) {
		throw new Error(
			`Failed to send text: ${
				error.response ? error.response.data : error.message
			}`
		);
	}
};
