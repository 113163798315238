const createUserDb = async (token, firstName, lastName, sendRequest) => {
    
	const body = JSON.stringify({
		firstName: firstName,
		lastName: lastName,
	});
	try {
		const response = await sendRequest(
			"/api/user/register",
			"POST",
			body,
			{
				"Content-Type": "application/json",
			},
			token
		);
		return response;
	} catch (err) {
		console.log(err);
	}
};

const registerWithInvite = async (token, firstName, lastName, inviteId, sendRequest) => {
	const body = JSON.stringify({
		firstName: firstName,
		lastName: lastName,
		inviteId: inviteId,
	});
	try {
		const response = await sendRequest(
			"/api/user/register-invite",
			"POST",
			body,
			{
				"Content-Type": "application/json",
			},
			token
		);
		return response;
	} catch (err) {
		console.log(err);
	}
};

export {
	createUserDb,
	registerWithInvite,
};