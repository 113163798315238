import React from 'react';

const NotInOrganization = () => {
    return (
        <div>
            <h1>Thanks for signing up!</h1>
            <p>You are not currently part of any organization. Please refresh the page, you should be added shortly. If this page doesn't update after a few minutes. contact your administrator to be added.</p>
        </div>
    );
};

export default NotInOrganization;
