// shared/context/TourContext.js
import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import Joyride from "react-joyride";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const TourContext = createContext();
const TOUR_TESTING = false; // Set this to `false` for production to track completion

// Define tour steps, with sequential navigation instructions
const tourSteps = [
    {
        target: '[data-tour-target="dashboard"]',
        content: "Welcome to your dashboard! Here you can see your video and script count for your organization, and your available avatars!",
        disableBeacon: true, // Only disables the beacon on this step
        route: "/", // Route for dashboard
    },
    {
        target: '[data-tour-target="sidebar"]',
        content: "Use this sidebar to navigate through different sections of the app.",
        placement: "right",
    },
    {
        target: '[data-tour-target="studio"]',
        content: "Click here to go to the Studio and manage your videos.",
        action: "navigate",
        route: "/videos", // Route for Studio
        placement: "right",
    },
    {
        target: '[data-tour-target="studio-content"]',
        content: "Here is the Studio where you can manage your videos.",
    },
    {
        target: '[data-tour-target="script-writer"]',
        content: "Click here to access the Script Writer.",
        action: "navigate",
        route: "/scripts", // Route for Script Writer
        placement: "right",
    },
    {
        target: '[data-tour-target="scripts-content"]',
        content: "Here you can see all your scripts.",
    },
    {
        target: '[data-tour-target="add-script"]',
        content: "Click here to add a new script.",
        placement: "right",
        
    },
    {
        target: '[data-tour-target="settings"]',
        content: "Click here to go to Settings.",
        action: "navigate",
        route: "/settings", // Route for Settings
        placement: "right",
    },
    {
        target: '[data-tour-target="settings-content"]',
        content: "Visit the Settings page to manage your preferences.",
        
    },
];


export const TourProvider = ({ children }) => {
    const [isTourRunning, setIsTourRunning] = useState(false);
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const navigate = useNavigate();

    // Show modal only if tour has not been completed or is in testing mode
    useEffect(() => {
        const hasCompletedTour = localStorage.getItem("hasCompletedTour");
        if (TOUR_TESTING || !hasCompletedTour) {
            setShowWelcomeModal(true);
        }
    }, []);

    const startTour = useCallback(() => {
        setShowWelcomeModal(false);
        setIsTourRunning(true);
    }, []);

    const skipTour = useCallback(() => {
        setIsTourRunning(false);
        setShowWelcomeModal(false);
        if (!TOUR_TESTING) {
            localStorage.setItem("hasCompletedTour", "true"); // Mark tour as completed
        }
    }, []);

    const handleTourCallback = useCallback(
        (data) => {
            const { status, action, index } = data;
    
            if (status === "finished" || status === "skipped") {
                setIsTourRunning(false);
                if (!TOUR_TESTING) {
                    localStorage.setItem("hasCompletedTour", "true");
                }
                navigate("/"); // Navigate to dashboard after tour completion
                return;
            }
    
            const currentStep = tourSteps[index];
            const prevStep = tourSteps[index - 1];
    
            // Handle navigation based on action
            if (action === "next" && currentStep?.action === "navigate") {
                navigate(currentStep.route);
            } else if (action === "prev" && prevStep?.route) {
                navigate(prevStep.route);
            }
        },
        [navigate]
    );
    
    
    
    

    return (
        <TourContext.Provider value={{ isTourRunning, startTour, skipTour }}>
            {children}
            <Modal show={showWelcomeModal} onHide={skipTour}>
                <Modal.Header closeButton>
                    <Modal.Title>Welcome to the App Tour!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>This tour will guide you through the main features of the app.</p>
                    <p>Click "Start Tour" to begin.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={skipTour}>
                        Skip Tour
                    </Button>
                    <Button variant="primary" onClick={startTour}>
                        Start Tour
                    </Button>
                </Modal.Footer>
            </Modal>

            {isTourRunning && (
                <Joyride
                    steps={tourSteps}
                    continuous
                    callback={handleTourCallback}
                    showSkipButton
                    disableOverlayClose
                    styles={{
                        options: {
                            zIndex: 9999,
                            primaryColor: "#1c004b",
                        },
                    }}
                    run={isTourRunning}
                    disableBeacon={true}
                    disableScrolling={true}
                />
            )}
        </TourContext.Provider>
    );
};

export const useTour = () => useContext(TourContext);
