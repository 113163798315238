import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useHttpClient } from "../shared/hooks/http-hook";

const PublicVideoPlayer = () => {
	const [searchParams] = useSearchParams();
	const videoId = searchParams.get("id");
	const { sendRequest } = useHttpClient();
	const [videoDetails, setVideoDetails] = useState(null);

	useEffect(() => {
		const fetchVideoDetails = async () => {
			if (videoId) {
				try {
					const response = await sendRequest(
						`/api/videos/${videoId}/public`,
						"GET",
						null,
						{}
					);
					setVideoDetails(response.data);
				} catch (err) {
					console.error("Error fetching video details:", err);
				}
			}
		};

		fetchVideoDetails();
	}, [videoId, sendRequest]);

	return (
		<div
			style={{
                height: "100vh",
				backgroundColor: "#1c004b",
			}}
		>
			<div
				className="container"
				style={{ display: "flex", alignItems: "center" }}
			>
				<img
					src="/logo-white.png"
					alt="logo"
					style={{
						width: "20%",
						padding: "20px 0"
					}}
				/>
			</div>
			{videoDetails ? (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						color: "white",
					}}
				>
					<video
						width="70%"
						height="auto"
						controls
						src={videoDetails.video_url}
					/>
				</div>
			) : (
				<p style={{ color: "white" }}>Video not found</p>
			)}
		</div>
	);
};

export default PublicVideoPlayer;
