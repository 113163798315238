import React, { useState, useEffect, useContext, useCallback } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { Button, Modal, ListGroup, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ToastError from "../../shared/elements/ToastError";

const OrgList = () => {
    const authContext = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [organizations, setOrganizations] = useState([]); // Initialize as an empty array
    const [showModal, setShowModal] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const navigate = useNavigate();

    const fetchOrganizations = useCallback(async () => {
        try {
            const response = await sendRequest(
                "/api/organizations",
                "GET",
                null,
                {
                    Authorization: "Bearer " + authContext.token,
                }
            );
            const responseData = response.data;

            if (Array.isArray(responseData)) {
                setOrganizations(responseData);
            } else {
                console.error('Response data is not an array:', responseData); // Debug log
                setOrganizations([]);
            }
        } catch (err) {
            console.error('Error fetching organizations:', err); // Debug log
        }
    }, [sendRequest, authContext.token]);

    useEffect(() => {
        fetchOrganizations();
    }, [fetchOrganizations]);

    const handleShowOrganization = (organization) => {
        setSelectedOrganization(organization);
        setShowModal(true);
    };

    const handleEditOrganization = () => {
        if (selectedOrganization) {
            navigate(`/organizations/edit/${selectedOrganization.name}`);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedOrganization(null);
    };

    return (
        <div>
            <ToastError show={!!error} message={error} onClose={clearError} />
            <Button variant="primary" onClick={() => navigate('/organizations/new')} style={{ marginBottom: '20px' }}>
                Add Organization
            </Button>
            {isLoading && <Spinner animation="border" />}
            <ListGroup>
                {organizations.length === 0 && <ListGroup.Item>No organizations found.</ListGroup.Item>}
                {organizations.map((organization) => (
                    <ListGroup.Item key={organization.name} onClick={() => handleShowOrganization(organization)}>
                        {organization.name}
                    </ListGroup.Item>
                ))}
            </ListGroup>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Organization Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedOrganization && (
                        <div>
                            <p><strong>Name:</strong> {selectedOrganization.name}</p>
                            {/*<p><strong>Super Admin UID:</strong> {selectedOrganization.orgAdminUID}</p>
                            <p><strong>Members:</strong> {selectedOrganization.members.join(", ")}</p>*/}
                            <p><strong>Avatar IDs:</strong> {selectedOrganization.avatarIds ? selectedOrganization.avatarIds.join(", ") : "None"}</p>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleEditOrganization}>
                        Edit Organization
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default OrgList;
