import React, { useState, useEffect, useContext, useCallback } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { Button, Modal, ListGroup, Spinner } from "react-bootstrap";
import ToastError from "../../shared/elements/ToastError";
import ClientEditor from "./ClientEditor";

const ClientList = () => {
    const authContext = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [clients, setClients] = useState([]); // Initialize as an empty array
    const [showModal, setShowModal] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);

    const fetchClients = useCallback(async () => {
        try {
            const response = await sendRequest(
                "/api/clients",
                "GET",
                null,
                {
                    Authorization: "Bearer " + authContext.token,
                }
            );
            const responseData = response.data; // Access the data property
            console.log('Fetched clients:', responseData); // Debug log
            console.log('Type of responseData:', typeof responseData); // Check type
            console.log('Is responseData an array:', Array.isArray(responseData)); // Check if array

            if (Array.isArray(responseData)) {
                setClients(responseData);
            } else {
                console.error('Response data is not an array:', responseData); // Debug log
                setClients([]);
            }
        } catch (err) {
            console.error('Error fetching clients:', err); // Debug log
        }
    }, [sendRequest, authContext.token]);

    useEffect(() => {
        fetchClients();
    }, [fetchClients]);

    const handleAddClient = () => {
        setSelectedClient(null);
        setShowModal(true);
    };

    const handleEditClient = (client) => {
        setSelectedClient(client);
        setShowModal(true);
    };

    const handleClientUpdated = () => {
        setShowModal(false);
        fetchClients();
    };

    return (
        <div>
            <ToastError show={!!error} message={error} onClose={clearError} />
            {(authContext.isSuperAdmin || authContext.orgRole === "admin") && (
                <Button variant="primary" onClick={handleAddClient} style={{ marginBottom: '20px' }}>
                Add Client
            </Button>)}
            {isLoading && <Spinner animation="border" />}
            <ListGroup>
                {clients.length === 0 && <ListGroup.Item>No clients found.</ListGroup.Item>}
                {clients.map((client) => (
                    <ListGroup.Item key={client.id} onClick={() => handleEditClient(client)}>
                        {client.clientName}
                    </ListGroup.Item>
                ))}
            </ListGroup>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedClient ? "Edit Client" : "Add Client"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ClientEditor client={selectedClient} onClientUpdated={handleClientUpdated} />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ClientList;
