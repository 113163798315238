import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import ToastError from "../../shared/elements/ToastError";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import { Button, Row, Form, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import AvatarSelection from "./AvatarSelection";
import EntityDropdown from "./EntityDropdown"; // Ensure this is imported

const videoSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  script: Yup.string().required("Script is required"),
  avatar: Yup.string().required("Avatar is required"),
  orgId: Yup.string().required("Organization is required"),
});

const VideoEditor = ({ onVideoUpdated = () => {} }) => {
  const authContext = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { videoId } = useParams();
  const navigate = useNavigate();
  const [userOrganizations, setUserOrganizations] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState("");
  const [showOrganizationDropdown, setShowOrganizationDropdown] = useState(false);
  const [initialValues, setInitialValues] = useState(null);

  const [videoData, setVideoData] = useState(null);
  const [organizationsLoaded, setOrganizationsLoaded] = useState(false);

  useEffect(() => {
    const fetchVideo = async () => {
      if (videoId) {
        try {
          const response = await sendRequest(
            `/api/videos/${videoId}`,
            "GET",
            null,
            {
              Authorization: "Bearer " + authContext.token,
            }
          );
          const video = response.data;
          setVideoData({
            title: video.title,
            script: video.script.id,
            avatar: video.avatar.id,
            orgId: video.orgId || "",
          });
        } catch (err) {
          console.error(err);
        }
      } else {
        setVideoData({
          title: "",
          script: "",
          avatar: "",
          orgId: "",
        });
      }
    };

    const fetchUserOrganizations = async () => {
      try {
        let organizations = [];

        if (authContext.isSuperAdmin) {
          const response = await sendRequest(
            "/api/organizations",
            "GET",
            null,
            {
              Authorization: "Bearer " + authContext.token,
            }
          );
          organizations = response.data;
        } else {
          organizations = authContext.memberships;
        }

        setUserOrganizations(organizations);

        const showDropdown =
          authContext.isSuperAdmin || organizations.length > 1;
        setShowOrganizationDropdown(showDropdown);
        setOrganizationsLoaded(true);
      } catch (err) {
        console.error("Error fetching organizations:", err);
      }
    };

    fetchVideo();
    fetchUserOrganizations();
  }, [
    sendRequest,
    authContext.token,
    videoId,
    authContext.isSuperAdmin,
    authContext.memberships,
  ]);

  useEffect(() => {
    if (videoData && organizationsLoaded) {
      let orgId = videoData.orgId;

      if (showOrganizationDropdown) {
        orgId = orgId || userOrganizations[0]?.orgId || "";
      } else if (userOrganizations.length === 1) {
        orgId = userOrganizations[0].orgId;
      } else {
        console.error("No organizations available for the user.");
      }

      setSelectedOrgId(orgId);

      setInitialValues({
        ...videoData,
        orgId,
      });
    }
  }, [videoData, organizationsLoaded, showOrganizationDropdown, userOrganizations]);

  const submitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      const payload = {
        ...values,
      };
      
      if (!payload.orgId) {
        console.error("Organization ID is required");
        return;
      }

      if (videoId) {
        await sendRequest(
          `/api/videos/${videoId}`,
          "PUT",
          JSON.stringify(payload),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authContext.token,
          }
        );
      } else {
        await sendRequest(
          "/api/videos",
          "POST",
          JSON.stringify(payload),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authContext.token,
          }
        );
      }
      onVideoUpdated();
      resetForm();
      navigate("/videos");
    } catch (err) {
      console.error(err.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <ToastError show={!!error} message={error} onClose={clearError} />
      {isLoading && <Spinner animation="border" />}
      {initialValues ? (
        <Formik
          initialValues={initialValues}
          validationSchema={videoSchema}
          onSubmit={submitHandler}
        >
          {(formikProps) => (
            <FormikForm noValidate onSubmit={formikProps.handleSubmit}>
              <Row className="mb-3">
                <Form.Group md="6" controlId="title">
                  <Form.Label>Title</Form.Label>
                  <Field as={Form.Control} type="text" name="title" />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <EntityDropdown
                  entity="scripts"
                  value={formikProps.values.script}
                  onChange={(e) =>
                    formikProps.setFieldValue("script", e.target.value)
                  }
                />
                {(authContext.isSuperAdmin ||
                  authContext.orgRole === "orgAdmin") && (
                  <Button variant="link" onClick={() => navigate("/scripts/new")}>
                    Add New Script
                  </Button>
                )}
              </Row>
              <Row className="mb-3">
                <AvatarSelection
                  value={{ avatarId: formikProps.values.avatar, lookId: formikProps.values.lookId }}
                  onChange={({ avatarId, lookId }) => {
                      formikProps.setFieldValue("avatar", avatarId);
                      formikProps.setFieldValue("look", lookId);
                  }}
                />
                {authContext.isSuperAdmin && (
                  <Button
                    variant="link"
                    onClick={() => navigate("/avatarconfig")}
                  >
                    Add New Avatar
                  </Button>
                )}
              </Row>
              {showOrganizationDropdown && (
                <Row className="mb-3">
                  <Form.Group controlId="orgId">
                    <Form.Label>Organization</Form.Label>
                    <Form.Control
                      as="select"
                      name="orgId"
                      value={formikProps.values.orgId || selectedOrgId}
                      onChange={(e) => {
                        setSelectedOrgId(e.target.value);
                        formikProps.setFieldValue("orgId", e.target.value);
                      }}
                    >
                      <option value="">Select an organization</option>
                      {userOrganizations.map((org) => (
                        <option key={org.orgId} value={org.orgId}>
                          {org.orgName || org.name}
                        </option>
                      ))}
                    </Form.Control>
                    <ErrorMessage
                      name="orgId"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                </Row>
              )}
              <Button
                className="submit-button"
                variant="primary"
                type="submit"
                disabled={
                  formikProps.isSubmitting ||
                  !formikProps.dirty ||
                  !formikProps.values.orgId
                }
              >
                {videoId ? "Update" : "Create"}
              </Button>
            </FormikForm>
          )}
        </Formik>
      ) : (
        <Spinner animation="border" />
      )}
    </React.Fragment>
  );
};

export default VideoEditor;
