import React from "react";
import Metrics from "../components/Metrics";

const Dashboard = (props) => {
	return (
		<div data-tour-target="dashboard">
			<Metrics />
		</div>
	);
};

export default Dashboard;
