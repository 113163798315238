import React from "react";
import { AuthContext } from "../../shared/context/auth-context";
import { useContext } from "react";
import UserList from "./UserList";

const AdminSettings = () => {
    const { orgName } = useContext(AuthContext);
    return ( 
        <React.Fragment>
            <div>You are an organization admin of {orgName}</div>
            <UserList />
        </React.Fragment>
        
    );
};

export default AdminSettings;