import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "./auth-context";

const OrgAdminRoute = () => {
    const authContext = useContext(AuthContext);

    if (!authContext.isAuthReady) {
        return <p>Loading...</p>;
    }

    if (!authContext.isLoggedIn) {
        return <Navigate to="/login" />;
    }

    const hasAccess = authContext.isSuperAdmin || authContext.orgRole === "orgAdmin";

    return hasAccess ? <Outlet /> : <Navigate to="/unauthorized" />;
};

export default OrgAdminRoute;
