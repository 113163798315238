import React, { useContext, useState } from "react";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import ToastError from "../../shared/elements/ToastError";

import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import { Button, Row, Form, Card, Toast } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

import * as Yup from "yup";

const updateSchema = Yup.object().shape({
    avatar_name: Yup.string().required("Avatar name is required"),
    id: Yup.string().required("Avatar ID is required"),
    voice_id: Yup.string().required("Voice ID is required"),
    orientation: Yup.string().required("Orientation is required"),
});

const AddAvatar = ({ onAvatarAdded }) => {
    const authContext = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [showToast, setShowToast] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [isImageValid, setIsImageValid] = useState(false);

    const initialValues = {
        avatar_name: "",
        id: "",
        voice_id: "",
        orientation: "horizontal",
    };

    const updateSubmitHandler = async (values, { setSubmitting, resetForm }) => {
        try {
            const response = await sendRequest(
                "/api/avatars",
                "POST",
                JSON.stringify(values),
                {
                    "Content-Type": "application/json",
                },
                authContext.token
            );
            if (response.status === 201 || response.status === 200) {
                //successful update, show success message / update page
                resetForm();
                setImageUrl("");
                setIsImageValid(false);
                setShowToast(true);
                onAvatarAdded();
            }
        } catch (err) {
            console.error('Error adding avatar:', err);
        } finally {
            setSubmitting(false);
        }
    };

    const handleAvatarIdBlur = (event) => {
        const avatarId = event.target.value;
        const imageUrl = `https://files2.heygen.ai/avatar/v3/${avatarId}/full/2.2/preview_target.webp`;

        // Create a new Image object to check if the image is valid
        const img = new Image();
        img.onload = () => {
            setImageUrl(imageUrl);
            setIsImageValid(true);
        };
        img.onerror = () => {
            setImageUrl("");
            setIsImageValid(false);
        };
        img.src = imageUrl;  // This will trigger the onload/onerror events
    };

    return (
        <React.Fragment>
            <ToastError show={!!error} message={error} onClose={clearError} />
            <div style={{ display: "flex", justifyContent: "center" }}>
            {isLoading && <Spinner animation="border" />}
                <Card style = {{ width: "30rem" }}>
                    <Card.Body>
                        <Card.Title>Add Avatar</Card.Title>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={updateSchema}
                            onSubmit={updateSubmitHandler}
                            enableReinitialize={true}
                        >
                            {(formikProps) => (
                                <FormikForm>
                                    <Form.Group as={Row} controlId="avatar_name">
                                        <Form.Label>Avatar Name</Form.Label>
                                        <Field
                                            as={Form.Control}
                                            type="text"
                                            name="avatar_name"
                                            placeholder="Enter Avatar Name"
                                        />
                                        <ErrorMessage name="avatar_name" component="div" />
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="id">
                                        <Form.Label>Avatar ID</Form.Label>
                                        <Field
                                            as={Form.Control}
                                            type="text"
                                            name="id"
                                            placeholder="Enter Avatar ID"
                                            onBlur={handleAvatarIdBlur}
                                        />
                                        <ErrorMessage name="id" component="div" />
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="voice_id">
                                        <Form.Label>Voice ID</Form.Label>
                                        <Field
                                            as={Form.Control}
                                            type="text"
                                            name="voice_id"
                                            placeholder="Enter Voice ID"
                                        />
                                        <ErrorMessage name="voice_id" component="div" />
                                    </Form.Group>
                                    {/*//add required dropdown for orientation veritcal or horizontal*/}
                                    <Form.Group as ={Row} controlId="orientation">
                                        <Form.Label>Orientation</Form.Label>
                                        <Field as="select" name="orientation">
                                            <option value="horizontal">Horizontal</option>
                                            <option value="vertical">Vertical</option>
                                        </Field>
                                    </Form.Group>
                                    {isImageValid && (
                                        <Row className="mb-3" style={{ textAlign: "center" }}>
                                            <img
                                                src={imageUrl}
                                                alt="Avatar Preview"
                                                style={{ width: "100%", maxHeight: "200px", objectFit: "contain" }}
                                            />
                                        </Row>
                                    )}
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        disabled={formikProps.isSubmitting || !formikProps.isValid || !isImageValid}
                                    >
                                        Submit
                                    </Button>
                                    
                                </FormikForm>
                            )}
                        </Formik>
                    </Card.Body>
                </Card>
                <div style={{ position: "fixed", top: 20, right: 20, zIndex: 1050 }}>
					<Toast
						onClose={() => setShowToast(false)}
						show={showToast}
						delay={3000}
						autohide
					>
						<Toast.Header>
							<strong className="me-auto">Success</strong>
							<small>Just now</small>
						</Toast.Header>
						<Toast.Body>Update successful!</Toast.Body>
					</Toast>
				</div>
            </div>
        </React.Fragment>
    );
};

export default AddAvatar;
