import React, { useContext, useState } from "react";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import ToastError from "../../shared/elements/ToastError";

import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import { Button, Row, Form, Card, Toast } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

//import { auth } from "../../config/firebase";
//import { updateEmail } from "firebase/auth";

import * as Yup from "yup";

const updateSchema = Yup.object().shape({
	//email: Yup.string().email("Invalid email format"),
	firstName: Yup.string(),
	lastName: Yup.string(),
});

const UpdateUser = ({ onUserUpdated }) => {
	const authContext = useContext(AuthContext);
	const { isLoading, error, sendRequest, clearError } = useHttpClient();
	const [showToast, setShowToast] = useState(false);

	const initialValues = {
		//email: "",
		firstName: "",
		lastName: "",
	};

	const updateSubmitHandler = async (values, { setSubmitting, resetForm }) => {
		const fieldsToUpdate = Object.keys(values).reduce((acc, key) => {
			if (values[key] !== initialValues[key]) {
				acc[key] = values[key];
			}
			return acc;
		}, {});

		try {
			/*if (fieldsToUpdate.email) {
				await updateEmail(auth.currentUser, fieldsToUpdate.email);
			}*/
			const response = await sendRequest(
				"/api/user/update",
				"PUT",
				JSON.stringify(fieldsToUpdate),
				{
					"Content-Type": "application/json",
				},
				authContext.token
			);
			if (response.status === 200) {
				//successful update, show success message / update page
				onUserUpdated();
				resetForm();
				setShowToast(true);
				setTimeout(() => setShowToast(false), 3000);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<React.Fragment>
			<ToastError show={!!error} message={error} onClose={clearError} />
			<div style={{ display: "flex", justifyContent: "center" }}>
				{isLoading && <Spinner animation="border" />}
				<Card style={{ width: "18rem" }}>
					<Card.Body>
						<Card.Title>Update Profile</Card.Title>
						<Formik
							initialValues={initialValues}
							validationSchema={updateSchema}
							onSubmit={updateSubmitHandler}
							enableReinitialize={true}
						>
							{(formikProps) => (
								<FormikForm noValidate onSubmit={formikProps.handleSubmit}>
									<Row className="mb-3">
										<Form.Group md="6" controlId="firstName">
											<Form.Label>First Name</Form.Label>
											<Field
												as={Form.Control}
												type="text"
												name="firstName"
												//isInvalid={touched.firstName && !!errors.firstName}
												//isValid={touched.firstName && !errors.firstName}
											/>
											<ErrorMessage
												name="firstName"
												component="div"
												className="text-danger"
											/>
										</Form.Group>
									</Row>
									<Row className="mb-3">
										<Form.Group md="6" controlId="lastName">
											<Form.Label>Last Name</Form.Label>
											<Field
												as={Form.Control}
												//value={values.lastName}
												type="text"
												name="lastName"
												//isInvalid={touched.lastName && !!errors.lastName}
												//isValid={touched.lastName && !errors.lastName}
											/>
											<ErrorMessage
												name="lastName"
												component="div"
												className="text-danger"
											/>
										</Form.Group>
									</Row>
									{/*<Row className="mb-3">
										<Form.Group md="6" controlId="email">
											<Form.Label>E-Mail</Form.Label>
											<Field
												as={Form.Control}
												//value={values.email}
												type="email"
												name="email"
												//isInvalid={touched.email && !!errors.email}
												//isValid={touched.email && !errors.email}
											/>
											<ErrorMessage
												name="email"
												component="div"
												className="text-danger"
											/>
										</Form.Group>
							</Row>*/}
									<Button
										className="submit-button"
										variant="primary"
										type="submit"
										disabled={formikProps.isSubmitting || !formikProps.dirty}
									>
										Update
									</Button>
								</FormikForm>
							)}
						</Formik>
					</Card.Body>
				</Card>
				<div style={{ position: "fixed", top: 20, right: 20, zIndex: 1050 }}>
					<Toast
						onClose={() => setShowToast(false)}
						show={showToast}
						delay={3000}
						autohide
					>
						<Toast.Header>
							<strong className="me-auto">Success</strong>
							<small>Just now</small>
						</Toast.Header>
						<Toast.Body>Update successful!</Toast.Body>
					</Toast>
				</div>
			</div>
		</React.Fragment>
	);
};

export default UpdateUser;


/*
FOR EMAIL VERIFCATION, A VERIFY EMAIL NEEDS TO BE SENT BEFORE CHANGING THE EMAIL

import { getAuth, sendEmailVerification, updateEmail } from "firebase/auth";

const auth = getAuth();
const user = auth.currentUser;

// Send a verification email to the new email address.
sendEmailVerification(user).then(() => {
  // Wait for the user to verify the new email address.
  // You can use a listener or a timeout to check when the email is verified.

  // Once the email is verified, update the email address.
  updateEmail(user, newEmail).then(() => {
    // Email address updated successfully.
  }).catch((error) => {
    // An error occurred while updating the email address.
  });
});*/