import React, { useState, useEffect, useContext, useCallback } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { ListGroup, Spinner, Modal, Button } from "react-bootstrap";
import ToastError from "../../shared/elements/ToastError";

const UserListAdmin = () => {
	const authContext = useContext(AuthContext);
	const { isLoading, error, sendRequest, clearError } = useHttpClient();
	const [users, setUsers] = useState([]);
	const [userRoles, setUserRoles] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);
	const [selectedMembership, setSelectedMembership] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [showRemoveAdminModal, setShowRemoveAdminModal] = useState(false);

	const fetchUsers = useCallback(async () => {
		try {
			const response = await sendRequest("/api/users", "GET", null, {
				Authorization: "Bearer " + authContext.token,
			});
			const responseData = response.data;
			setUsers(responseData);
		} catch (err) {
			console.error("Error fetching users:", err);
		}
	}, [sendRequest, authContext.token]);

	useEffect(() => {
		fetchUsers();
	}, [fetchUsers]);

	const handleUserClick = async (user) => {
		try {
			const response = await sendRequest(`/api/roles/${user.id}`, "GET", null, {
				Authorization: "Bearer " + authContext.token,
			});
			setUserRoles(response.data);
			setSelectedUser(user);
			setShowModal(true);
		} catch (err) {
			console.error(`Error fetching roles for user ${user.id}:`, err);
		}
	};

	const handleMembershipClick = (membership) => {
		setSelectedMembership(membership);
		setShowRemoveModal(true);
	};

	const handleRemoveConfirm = async () => {
		if (!selectedUser || !selectedMembership) return;
		try {
			await sendRequest(
				"/api/organizations/remove-user",
				"POST",
				JSON.stringify({
					userId: selectedUser.id,
					orgId: selectedMembership.orgId,
				}),
				{
					"Content-Type": "application/json",
					Authorization: "Bearer " + authContext.token,
				}
			);
			setUserRoles((prevRoles) => ({
				...prevRoles,
				memberships: prevRoles.memberships.filter(
					(m) => m.orgId !== selectedMembership.orgId
				),
			}));
			setShowRemoveModal(false);
			setSelectedMembership(null);
		} catch (err) {
			console.error("Error removing user from organization:", err);
		}
	};

	const handleRemoveAdminClick = (membership) => {
		setSelectedMembership(membership);
		setShowRemoveAdminModal(true);
	};

	const handleRemoveAdminConfirm = async () => {
		if (!selectedUser || !selectedMembership) return;
		try {
			await sendRequest(
				"/api/organizations/remove-admin",
				"POST",
				JSON.stringify({
					userId: selectedUser.id,
					orgId: selectedMembership.orgId,
				}),
				{
					Authorization: "Bearer " + authContext.token,
					"Content-Type": "application/json",
				}
			);
			setUserRoles((prevRoles) => ({
				...prevRoles,
				memberships: prevRoles.memberships.map((m) =>
					m.orgId === selectedMembership.orgId ? { ...m, role: "member" } : m
				),
			}));
			setShowRemoveAdminModal(false);
			setSelectedMembership(null);
		} catch (err) {
			console.error("Error removing admin role from user:", err);
		}
	};


	const handleCloseModal = () => {
		setShowModal(false);
		setUserRoles(null);
		setSelectedUser(null);
	};

	return (
		<div>
			<ToastError show={!!error} message={error} onClose={clearError} />
			{isLoading && <Spinner animation="border" />}
			<ListGroup>
				{users.length === 0 && <ListGroup.Item>No users found.</ListGroup.Item>}
				{users.map((user) => (
					<ListGroup.Item
						key={user.id}
						onClick={() => handleUserClick(user)}
						style={{ cursor: "pointer" }}
					>
						{user.firstName} {user.lastName} - {user.email}
					</ListGroup.Item>
				))}
			</ListGroup>
			<Modal show={showModal} onHide={handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedUser ? `Roles for ${selectedUser.email}` : "User Roles"}
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{selectedUser && userRoles ? (
						<div>
							<p>
								<strong>User Info:</strong> {selectedUser.firstName}{" "}
								{selectedUser.lastName} - {selectedUser.email}
							</p>
							<h5>Memberships:</h5>
							{userRoles.memberships && userRoles.memberships.length > 0 ? (
								<ul>
									{userRoles.memberships.map((membership, index) => (
										<li key={index} >
											<strong>{membership.orgName}</strong> {membership.role} <br />
											{membership.role === "orgAdmin" && (
												<Button
													variant="warning"
													size="sm"
													onClick={() => handleRemoveAdminClick(membership)}
													style={{ marginTop: "5px" }}
												>
													Remove Admin Role
												</Button>
											)}
											<Button size="sm" variant="danger" onClick={() => handleMembershipClick(membership)}>
												Remove
											</Button>
										</li>
									))}
								</ul>
							) : (
								<p>No memberships found.</p>
							)}
						</div>
					) : (
						<Spinner animation="border" />
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseModal}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showRemoveModal} onHide={() => setShowRemoveModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Remove User from Organization</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{selectedMembership && selectedUser ? (
						<div>
							<p>
								Are you sure you want to remove{" "}
								<strong>{selectedUser.firstName} {selectedUser.lastName}</strong> from{" "}
								<strong>{selectedMembership.orgName}</strong>?
							</p>
						</div>
					) : (
						<Spinner animation="border" />
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowRemoveModal(false)}>
						Cancel
					</Button>
					<Button variant="danger" onClick={handleRemoveConfirm}>
						Remove
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showRemoveAdminModal} onHide={() => setShowRemoveAdminModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Remove Admin Role</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{selectedMembership && selectedUser ? (
						<p>
							Are you sure you want to remove the admin role for{" "}
							{selectedUser.firstName} {selectedUser.lastName} in the organization{" "}
							<strong>{selectedMembership.orgName}</strong>?
						</p>
					) : (
						<Spinner animation="border" />
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowRemoveAdminModal(false)}>
						Cancel
					</Button>
					<Button variant="warning" onClick={handleRemoveAdminConfirm}>
						Remove Admin Role
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default UserListAdmin;
