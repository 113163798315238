//import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { React, useContext, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

import { AuthContext } from "./shared/context/auth-context";
import { TourProvider } from "./shared/context/TourContext";
import SuperAdminRoute from "./shared/context/SuperAdminRoute";
import OrgAdminRoute from "./shared/context/OrgAdminRoute";
import SessionManager from "./components/SessionManager";
import Dashboard from "./base/pages/Dashboard";
import Auth from "./user/pages/Auth";
import Settings from "./user/pages/Settings";

import SideBar from "./base/components/SideBar";

import ClientList from "./workspace/components/ClientList";
import ScriptList from "./workspace/components/ScriptList";
import ScriptEditor from "./workspace/components/ScriptEditor";
import VideoList from "./workspace/components/VideoList";
import VideoEditor from "./workspace/components/VideoEditor";
import AvatarViewer from "./workspace/pages/AvatarViewer";
import AdminSettings from "./user/pages/AdminSettings";
import OrgSettings from "./user/pages/OrgSettings";
import NewOrg from "./user/components/NewOrg";
import NotInOrganization from "./components/NotInOrganization";
import AdminAddAvatar from "./heygen/pages/AdminAddAvatar";
import InvitePage from "./user/pages/InvitePage";
import ForgotPassword from "./user/pages/ForgotPassword";
import VideoPlayer from "./user/pages/VideoPlayer";
import PublicVideoPlayer from "./components/PublicVideoPlayer";
import UserListAdmin from "./user/pages/UserListAdmin";
import AuthAction from "./user/pages/AuthAction";

function App() {
	const { isLoggedIn, isAuthReady, isSuperAdmin, isInOrganization } =
		useContext(AuthContext);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		ReactGA.send({ hitType: "pageview", page: location.pathname });
	}, [location]);

	useEffect(() => {
		if (
			!isLoggedIn &&
			![
				"/invite",
				"/forgot-password",
				"/",
				"/video",
				"/share",
				"/auth/action",
			].includes(location.pathname)
		) {
			navigate("/");
		}
	}, [isLoggedIn, navigate, location.pathname]);

	//Auth delay for route rendering
	if (!isAuthReady) {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100vh",
					width: "100vw",
				}}
			>
				<img
					src="/R-gradient.png"
					alt="loading..."
					style={{ 
						width: "100px",
						height: "auto",
						animation: "pulseAndFade 1.5s ease-in-out infinite",
					}}
				/>
			</div>
		);
	}

	let routes;

	if (isLoggedIn) {
		if (!isSuperAdmin && !isInOrganization) {
			return (
				<main>
					<NotInOrganization />
				</main>
			);
		}
		routes = (
			<div className="app-layout">
				<div className="sidebar">
					<SideBar />
				</div>
				<div className="app-content">
					<Routes>
						<Route path="/" element={<Dashboard />} />
						<Route path="/session" element={<OrgAdminRoute />}>
							<Route index element={<SessionManager />} />
						</Route>
						{/*<Route path="/avatarconfig" element={<HeygenConfig />} />*/}
						<Route path="/avatarlist" element={<AvatarViewer />} />
						<Route path="/add-avatar" element={<OrgAdminRoute />}>
							<Route index element={<AdminAddAvatar />} />
						</Route>
						<Route path="/clients" element={<ClientList />} />
						<Route path="/settings" element={<Settings />} />
						<Route path="/admin" element={<SuperAdminRoute />}>
							<Route index element={<AdminSettings />} />
						</Route>
						<Route path="/organizations" element={<OrgAdminRoute />}>
							<Route index element={<OrgSettings />} />
						</Route>
						<Route path="/organizations/new" element={<SuperAdminRoute />}>
							<Route index element={<NewOrg />} />
						</Route>
						<Route path="/user-list" element={<SuperAdminRoute />}>
							<Route index element={<UserListAdmin />} />
						</Route>
						<Route path="/scripts" element={<ScriptList />} />
						<Route path="/scripts/new" element={<ScriptEditor />} />
						<Route path="/scripts/edit/:scriptId" element={<ScriptEditor />} />
						{/*<Route path="/campaigns" element={<CampaignList />} />*/}
						<Route path="/videos" element={<VideoList />} />
						<Route path="/videos/new" element={<VideoEditor />} />
						<Route path="/videos/edit/:videoId" element={<VideoEditor />} />
						<Route path="/video" element={<VideoPlayer />} />
						<Route path="/share" element={<PublicVideoPlayer />} />
						<Route path="/auth/action" element={<AuthAction />} />
					</Routes>
				</div>
			</div>
		);
	} else {
		routes = (
			<Routes>
				<Route path="/" element={<Auth />} />
				<Route path="/invite" element={<InvitePage />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route path="/video" element={<VideoPlayer />} />
				<Route path="/share" element={<PublicVideoPlayer />} />
				<Route path="/auth/action" element={<AuthAction />} />
			</Routes>
		);
	}

	return (
		<>
			<main>
				{isLoggedIn && (isSuperAdmin || isInOrganization) ? (
					<TourProvider>{routes}</TourProvider>
				) : (
					routes
				)}
			</main>
		</>
	);
}

export default App;
