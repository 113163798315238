import { useState, useEffect, useCallback } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";

export const useFetchEntities = (entity, token, filter = null) => {
    const { sendRequest } = useHttpClient();
    const [entities, setEntities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchEntities = useCallback(async () => {
        setLoading(true);
        try {
            let url = `/api/${entity}`;
            if (filter) {
                const queryParams = new URLSearchParams(filter).toString();
                url += `?${queryParams}`;
            }
            const response = await sendRequest(url, "GET", null, {
                Authorization: "Bearer " + token,
            });
            setEntities(response.data);
        } catch (err) {
            console.error(`Error fetching entities: ${err.message}`);
            setError(err.message);
        }
        setLoading(false);
    }, [sendRequest, entity, token, filter]);

    useEffect(() => {
        console.log(`useFetchEntities useEffect - filter: ${JSON.stringify(filter)}`);
        fetchEntities();
    }, [fetchEntities, filter]);

    return { entities, loading, error, fetchEntities };
};
